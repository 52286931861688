import {
	CertificateType,
	SelectionOfCertificateFormInputs,
} from "@shared/stores"
import { TFunction } from "i18next"
import * as Yup from "yup"

export const useSelectionOfCertificateValidationSchema = (
	t: TFunction<"features", "selectionOfMaterialForm">,
): Yup.Schema<SelectionOfCertificateFormInputs> => {
	return Yup.object().shape(
		{
			certificateType: Yup.mixed<CertificateType>().required(
				t("errorMessages.certificateTypeRequired"),
			),
			project: Yup.string().when(["newProject"], (newProject, schema) => {
				if (!newProject[0]) {
					return schema.required(
						t("errorMessages.projectReferenceRequired"),
					)
				}
				return schema
			}),
			newProject: Yup.string().when(["project"], (project, schema) => {
				if (!project[0]) {
					return schema.required(
						t("errorMessages.projectReferenceRequired"),
					)
				}
				return schema
			}),
		},
		[["newProject", "project"]],
	)
}
