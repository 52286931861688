import { projectsApi } from "@shared/api"
import i18n from "@shared/i18n"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { ActivityHistory, ActivityHistoryItem } from "../model"
import { ActivityHistoryStepContent } from "../ui/activity-history-item-card/config"

type State = {
	activityHistory?: ActivityHistory
}

type Actions = {
	fetchActivityHistory: (projectId: string) => Promise<void>
}

export const useActivityHistoryStore = create<State & Actions>()(
	immer(set => ({
		activityHistory: undefined,

		fetchActivityHistory: async (projectId: string) => {
			try {
				const activityHistory =
					await projectsApi.getActivityHistory(projectId)

				const measurements = activityHistory.measurements.reduce(
					(result, measurement) => {
						const config =
							ActivityHistoryStepContent[measurement.name]

						if (!config) return result

						return [
							...result,
							{
								id: measurement.id,
								title: i18n.t(config.title),
								description: i18n.t(config.description),
								image: config.image,
								startDate: measurement.startDate,
								endDate: measurement.endDate,
								proofIds: measurement.transactionIds,
							},
						]
					},
					[] as ActivityHistoryItem[],
				)

				const transactions = activityHistory.transactions.reduce(
					(result, transaction) => {
						const config =
							ActivityHistoryStepContent[transaction.name]

						if (!config) return result

						return [
							...result,
							{
								id: transaction.id,
								title: i18n.t(config.title),
								description: i18n.t(config.description),
								image: config.image,
								startDate: transaction.startDate,
								endDate: transaction.endDate,
								proofIds:
									transaction.materialsWithTransactionIds,
							},
						]
					},
					[] as ActivityHistoryItem[],
				)

				set(state => {
					state.activityHistory = {
						transactions,
						measurements,
					}
				})
			} catch {
				spawnErrorToast("features:activitiyHistory.errors.fetch")
			}
		},
	})),
)
