import { materialsApi } from "@shared/api/materials"
import {
	PaginationActions,
	PaginationState,
	usePaginationStore,
} from "@shared/stores"
import { spawnErrorToast } from "@sustainability/fundamental"
import i18next from "i18next"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { formatInvoicesForMaterialItem } from "../utils"
import { FormattedInvoicesForMaterialItem } from "./types"

type State = PaginationState & {
	invoices: FormattedInvoicesForMaterialItem[]
}

type Actions = PaginationActions & {
	fetchInvoicesForMaterialItem: (
		contractNumber: string,
		materialNumber: string,
	) => Promise<void>
	resetStore: () => void
}

export const useInvoicesForMaterialStore = create<State & Actions>()(
	immer((set, get, api) => ({
		...usePaginationStore(set, get, api),

		invoices: [],

		fetchInvoicesForMaterialItem: async (
			contractNumber: string,
			materialNumber: string,
		) => {
			try {
				const response = await materialsApi.getInvoicesForMaterialItem(
					contractNumber,
					materialNumber,
					get().paginationRequest,
				)
				const formattedInvoices = formatInvoicesForMaterialItem(
					response.invoices,
				)

				set(state => {
					state.invoices.push(...formattedInvoices)
				})
				get().updatePagination(response, get().invoices.length)
			} catch {
				spawnErrorToast(i18next.t("features:invoicesList.errorMessage"))
			}
		},
		resetStore: () => {
			set(state => {
				state.invoices = []
			})
			get().resetPagination()
		},
	})),
)
