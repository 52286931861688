import { Button, CardHeader, icon } from "@sustainability/fundamental"

interface SectionInfoHeaderProps {
	title: string
	"data-cy"?: string
	"data-cy-button"?: string
	buttonTitle?: string
	onClick?: () => void
}

export const SectionInfoHeader: React.FC<SectionInfoHeaderProps> = ({
	"data-cy": dataCy,
	"data-cy-button": dataCyButton,
	title,
	buttonTitle,
	onClick,
}) => (
	<>
		<CardHeader
			data-cy={dataCy}
			className="!text-wrap xs:w-[calc(100%-70px)] xl:w-auto"
			aside={
				<Button
					data-cy={dataCyButton}
					variant="tertiary"
					icon={icon.mdiInformationOutline}
					onClick={onClick}
				>
					<span className="xl:block xs:hidden">{buttonTitle}</span>
				</Button>
			}
		>
			{title}
		</CardHeader>
	</>
)
