import NoContractsImport from "@shared/assets/no-contracts.png"

import LogoDnvImport from "./dnvassurance.png"
import EvoBuildImport from "./evoBuild.png"
import EvoZeroImport from "./evozero.png"
import translationsDe from "./locales/de-DE.json"
import translationsEn from "./locales/en-GB.json"
import NotFound from "./notFoundPage.png"
import SucessTransferImport from "./sucessTransfer.png"
import TransactionsImage from "./transactionOverView.png"

export const LogoDnv = LogoDnvImport
export const LogoEvoZero = EvoZeroImport
export const NoContracts = NoContractsImport
export const SucessTransfer = SucessTransferImport
export const Transactions = TransactionsImage
export const LogoEvoBuild = EvoBuildImport
export const NotFoundPage = NotFound
export const globalTranslations = {
	en: translationsEn,
	de: translationsDe,
}
