import { Icon, icon } from "@sustainability/fundamental"

export type ProjectStatusIconProps = {
	status: "pending" | "partiallyClaimed" | "claimed"
}

export const ProjectSatusIcon: React.FC<ProjectStatusIconProps> = ({
	status,
}) => {
	if (status === "claimed") {
		return <Icon path={icon.mdiCheck} />
	}
	if (status) {
		return <Icon path={icon.mdiTimerSandComplete} />
	}

	return <Icon path={icon.mdiTimerSandEmpty} />
}
