import { authenticationClient } from "@carbonbank/api"

import { Country } from "./types"

export const configurationsApi = {
	getCounties: (): Promise<Country[]> =>
		authenticationClient
			.get("/configurations/countries")
			.then(response => response.data),
}
