import { carbonBankWithAuthClient } from "@carbonbank/api/client"
import { generateGetUrl } from "@carbonbank/api/utils"

import { PaginationRequest } from "../types"
import {
	CertificatesForMaterialItemData,
	GetMaterialsResponse,
	InvoicesForMaterialItemData,
	Material,
} from "./types"

export const materialsApi = {
	get: (showTransferred: boolean): Promise<GetMaterialsResponse> =>
		carbonBankWithAuthClient
			.get(
				generateGetUrl(`/customers/material-items`, {
					showTransferred,
				}),
			)
			.then(response => response.data),
	getById: (
		contractNumber: string,
		materialNumber: string,
	): Promise<Material> =>
		carbonBankWithAuthClient
			.get(
				`/contracts/${contractNumber}/material-items/${materialNumber}`,
			)
			.then(response => response.data),
	getInvoicesForMaterialItem: (
		contractNumber: string,
		materialNumber: string,
		paginationRequest: PaginationRequest,
	): Promise<InvoicesForMaterialItemData> =>
		carbonBankWithAuthClient
			.get(
				`/contracts/${contractNumber}/material-items/${materialNumber}/invoices`,
				{
					params: paginationRequest,
				},
			)
			.then(response => response.data),
	getCertificatesForMaterialItem: (
		contractNumber: string,
		materialNumber: string,
	): Promise<CertificatesForMaterialItemData> =>
		carbonBankWithAuthClient
			.get(
				`/contracts/${contractNumber}/material-items/${materialNumber}/certificates`,
			)
			.then(response => response.data),
}
