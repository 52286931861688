import {
	CementManufacturing,
	ConcreteProduct,
	DnvAssuranceTransaction,
	PermanentStorage,
	ReceivingTerminal,
	TemporaryStorage,
	TransportPreparation,
	TransportViaShip,
	FinalOwner,
} from "@shared/ui/activity-history-graphics"

import { ModuleKeys } from "../../i18n"

const translationPrefix = "transactionDetails.components.transactionsHistory"

type TransactionHistoryCardListConstantType = {
	title: ModuleKeys
	image: string
	description: ModuleKeys
}

export const TransactionHistoryCardListConstant: TransactionHistoryCardListConstantType[] =
	[
		{
			title: `${translationPrefix}.firstTitle`,
			image: CementManufacturing,
			description: `${translationPrefix}.firstDescription`,
		},
		{
			title: `${translationPrefix}.secondTitle`,
			image: TemporaryStorage,
			description: `${translationPrefix}.secondDescription`,
		},
		{
			title: `${translationPrefix}.thirdTitle`,
			image: TransportPreparation,
			description: `${translationPrefix}.thirdDescription`,
		},
		{
			title: `${translationPrefix}.fourthTitle`,
			image: TransportViaShip,
			description: `${translationPrefix}.fourthDescription`,
		},
		{
			title: `${translationPrefix}.fifthTitle`,
			image: ReceivingTerminal,
			description: `${translationPrefix}.fifthDescription`,
		},
		{
			title: `${translationPrefix}.sixthTitle`,
			image: PermanentStorage,
			description: `${translationPrefix}.sixthDescription`,
		},
		{
			title: `${translationPrefix}.seventhTitle`,
			image: DnvAssuranceTransaction,
			description: `${translationPrefix}.seventhDescription`,
		},
		{
			title: `${translationPrefix}.eighthTitle`,
			image: ConcreteProduct,
			description: `${translationPrefix}.eighthDescription`,
		},
		{
			title: `${translationPrefix}.ninethTitle`,
			image: FinalOwner,
			description: `${translationPrefix}.ninethDescription`,
		},
	]
