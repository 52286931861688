import {
	getSelectionOfCertificateTypeFormWithMaterialUrl,
	materials,
} from "@shared/routes"
import {
	useCertificateCreationFormStore,
	useLoadingStore,
} from "@shared/stores"
import { PageHeader } from "@sustainability/fundamental"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { useMaterialDetailsHeaderStore } from "../store"
import { CreateCertificateActionButton } from "./create-certificate-action-button"
import { InvoicedQuantityProgressBar } from "./invoiced-quantity-progress-bar"
import { RemainForCertificateCreationKpi } from "./remain-for-certificate-creation-kpi"

interface MaterialDetailsHeaderProps {
	pageName: string
	contractNumber: string
	materialNumber: string
	isLoading: boolean
}

export const MaterialDetailsHeader: React.FC<MaterialDetailsHeaderProps> = ({
	pageName,
	contractNumber,
	materialNumber,
	isLoading,
}) => {
	const FEATURE_NAME = "materialDetailsHeader"

	const { t } = useTranslation("features", {
		keyPrefix: "materialDetailsHeader",
	})

	const navigate = useNavigate()

	const setDefaultMaterial = useCertificateCreationFormStore(
		state => state.setDefaultMaterial,
	)

	const [materialHeaderData, fetchMaterial, resetStore] =
		useMaterialDetailsHeaderStore(state => [
			state.materialHeaderData,
			state.fetchMaterial,
			state.resetStore,
		])

	const [addFeatureToLoading, removeFeatureFromLoading] = useLoadingStore(
		state => [state.addFeatureToLoading, state.removeFeatureFromLoading],
	)

	const fetchMaterialData = async () => {
		addFeatureToLoading({ page: pageName, feature: FEATURE_NAME })

		await fetchMaterial(contractNumber, materialNumber)

		removeFeatureFromLoading({ page: pageName, feature: FEATURE_NAME })
	}

	useEffect(() => {
		fetchMaterialData()

		return () => resetStore()
	}, [])

	if (isLoading || !materialHeaderData) {
		return <></>
	}

	const handleCreateCertificateButtonClick = () => {
		setDefaultMaterial(
			materialHeaderData.materialDescription,
			materialNumber,
			contractNumber,
		)
		navigate(
			getSelectionOfCertificateTypeFormWithMaterialUrl(
				contractNumber,
				materialNumber,
			),
		)
	}

	return (
		<div data-cy="material-details-header-container">
			<PageHeader
				backToPageUrlOnClick={() => navigate(materials)}
				backToPageLabel={t("backLinkTitle")}
				data-cy="material-details-header"
				className="md:grid-rows-1 md:grid-cols-[auto_1fr] xs:grid-cols-1 gap-y-[14px] pl-2 md:pl-6"
				subtitle={materialHeaderData.contractNumber}
				aside={
					<CreateCertificateActionButton
						onClick={handleCreateCertificateButtonClick}
					/>
				}
				asideClassname="md:w-auto md:block xs:w-full xs:flex xs:justify-center !pl-4 pr-4"
			>
				{materialHeaderData.materialDescription}
			</PageHeader>
			<div
				data-cy="material-details-header-kpi-container"
				className="flex flex-wrap gap-y-[12px] md:justify-start xs:justify-center gap-[48px] w-full py-[5px] px-6 md:px-12"
			>
				<InvoicedQuantityProgressBar />
				<RemainForCertificateCreationKpi />
			</div>
		</div>
	)
}
