import { Kpi } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

import { useMaterialDetailsHeaderStore } from "../../store"

export const RemainForCertificateCreationKpi: React.FC = () => {
	const { t } = useTranslation("features", {
		keyPrefix: "materialDetailsHeader",
	})

	const remainForCertificateCreationKpiData = useMaterialDetailsHeaderStore(
		state => state.remainForCertificateCreationKpiData,
	)

	if (!remainForCertificateCreationKpiData) {
		return <></>
	}

	return (
		<div
			data-cy="remain-for-certificate-creation-kpi-container"
			className="flex flex-col items-center xl:w-[363px] md:w-[288px] xs:w-full"
		>
			<Kpi
				data-cy="remain-for-certificate-creation-kpi"
				className="flex flex-col items-start w-full"
				size="small"
				label={t("remainingForCertificateCreation")}
				value={remainForCertificateCreationKpiData.value}
			/>
		</div>
	)
}
