import {
	Button,
	CardFooter,
	CardHeader,
	clx,
	DetailsCard,
	icon,
} from "@sustainability/fundamental"
import { ReactNode, useState } from "react"
import { useTranslation } from "react-i18next"

interface FormSummaryProps {
	dataCy: string
	summaryTitle: string
	summaryContent: ReactNode
	submitTitle: string
	submitIcon: string
	backTitle?: string
	onBack?: () => void
	isLessThanMediumScreen: boolean
	isValid: boolean
}

export const FormSummary: React.FC<FormSummaryProps> = ({
	dataCy,
	summaryTitle,
	summaryContent,
	submitTitle,
	submitIcon,
	backTitle,
	onBack,
	isLessThanMediumScreen,
	isValid,
}) => {
	const { t } = useTranslation("shared", { keyPrefix: "formWithSummary" })

	const [isSummaryOpen, setSummaryOpen] = useState(false)

	const isSummaryContentVisible =
		(isLessThanMediumScreen && isSummaryOpen) || !isLessThanMediumScreen
	const visibilitySummaryContentStyle = isSummaryContentVisible
		? "visible"
		: "hidden"

	return (
		<div
			data-cy={`${dataCy}-summary-container`}
			className={clx(
				"xl:w-[calc(39%-24px)] lg:w-[calc(41.4%-24px)] max-lg:w-full max-lg:fixed max-lg:left-0 max-lg:bottom-0",
				isSummaryOpen &&
					"max-lg:h-full max-lg:bg-overlay_background_80 ",
			)}
			onClick={() => isSummaryOpen && setSummaryOpen(false)}
		>
			<DetailsCard
				data-cy={`${dataCy}-summary-card`}
				className={clx(
					isLessThanMediumScreen &&
						isSummaryOpen &&
						"max-lg:fixed max-lg:w-full max-lg:bottom-0 max-lg:left-0",
				)}
			>
				<CardHeader
					data-cy={`${dataCy}-summary-header`}
					className="w-full flex justify-between items-center"
				>
					{summaryTitle}
					{isLessThanMediumScreen && (
						<Button
							icon={
								isSummaryOpen
									? icon.mdiChevronDown
									: icon.mdiChevronUp
							}
							variant="tertiary"
							onClick={() =>
								setSummaryOpen(isSummaryOpen => !isSummaryOpen)
							}
							type="button"
						>
							{isSummaryOpen ? (
								<span>{t("hideButtonTitle")}</span>
							) : (
								<span>{t("showButtonTitle")}</span>
							)}
						</Button>
					)}
				</CardHeader>
				<div
					data-cy={`${dataCy}-summary-content-container`}
					className={clx(
						"flex flex-col gap-y-[12px]",
						visibilitySummaryContentStyle,
					)}
				>
					{summaryContent}
				</div>
				<CardFooter
					data-cy={`${dataCy}-summary-footer`}
					className="flex justify-between gap-[12px]"
				>
					{backTitle && (
						<Button
							data-cy={`${dataCy}-summary-back-button`}
							className="w-[94px]"
							variant="secondary"
							icon={icon.mdiArrowLeft}
							onClick={onBack}
							type="button"
						>
							{backTitle}
						</Button>
					)}
					<Button
						className="w-full"
						data-cy={`${dataCy}-summary-submit-button`}
						icon={submitIcon}
						disabled={!isValid}
					>
						{submitTitle}
					</Button>
				</CardFooter>
			</DetailsCard>
		</div>
	)
}
