import { CycleStatus, CommentType } from "@carbonbank/api"
import { useAuthStore, useCarbonSavingsModalStore } from "@carbonbank/store"
import { FormikSubmitCallback } from "@carbonbank/types"
import { formatTimestamp, formatNumber } from "@carbonbank/utils"
import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextRegular,
	Kpi,
	DetailsCard,
	DetailsCardHeader,
	Label,
	spawnSuccessToast,
} from "@sustainability/fundamental"
import { Formik, Form } from "formik"
import React from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import {
	cycleStatusByScenario,
	commentStatusByScenario,
	formFieldsByScenario,
	scenarioToButtonPropsMap,
	useValidationSchema,
} from "./constants"
import { scenarioToastMessageKeyMap } from "./utils"

interface AssureCarbonSavingsModalProps {
	plantNumber: string
	onSubmit: () => Promise<void>
}

export const AssureCarbonSavingsModal: React.FC<
	AssureCarbonSavingsModalProps
> = ({ plantNumber, onSubmit }) => {
	const { t } = useTranslation("cs", {
		keyPrefix: "components.assureModal",
	})

	const { t: tFuel } = useTranslation("cb", {
		keyPrefix: "common.fuel",
	})

	const { t: tText } = useTranslation("cb", {
		keyPrefix: "common.texts",
	})

	const { t: tUnits } = useTranslation("cb", {
		keyPrefix: "units",
	})

	const [decodedToken] = useAuthStore(s => [s.decodedToken])
	const { pathname } = useLocation()

	const [isOpen, scenario, hasScenario, data, closeModal, submitData] =
		useCarbonSavingsModalStore(state => [
			state.isOpen,
			state.getScenario(),
			!!state.scenario,
			state.data,
			state.closeModal,
			state.submitData,
		])

	if (!data) {
		return
	}

	const {
		cycle,
		shipmentDate,
		plant,
		totalSavings,
		co2PumpedIntoStorageDate,
		totalBiogenic,
		totalFossil,
	} = data

	const formFields = { serialNumber: "", comment: "" }

	const onAssure: FormikSubmitCallback<typeof formFields> = async (
		{ serialNumber, comment },
		{ setSubmitting, resetForm },
	) => {
		await submitData(
			plantNumber,
			{
				cycle: cycle,
				status: cycleStatusByScenario[scenario],
				serialNumber,
				requestInformationDetails:
					cycleStatusByScenario[scenario] ===
					CycleStatus.RequireMoreInformation
						? {
								assetNumber: cycle,
								name: decodedToken?.name.split(" ")[0],
								fullName: decodedToken?.name,
								email: decodedToken?.email,
								comment: comment,
								buttonLink: pathname,
							}
						: undefined,
			},
			cycleStatusByScenario[scenario] !== CycleStatus.AwaitingAssurance
				? {
						username: decodedToken?.name ?? "",
						relatedEntityId: cycle,
						status: commentStatusByScenario[scenario],
						type: CommentType.CarbonSavings,
						content: comment,
					}
				: undefined,
		)

		await onSubmit()

		const toastMessage = t(scenarioToastMessageKeyMap[scenario], {
			id: cycle,
		})

		spawnSuccessToast(toastMessage, {
			"data-cy": "success-toast",
		})

		setSubmitting(false)
		resetForm()
	}

	const [submitButtonLabel, submitButtonVariant] =
		scenarioToButtonPropsMap[scenario]

	const FieldsComponent = formFieldsByScenario[scenario]
	const validationSchema = useValidationSchema(scenario)

	const carbonSavingsUnit = tUnits("kg")

	return (
		<Modal
			data-cy="assure-savings-modal"
			open={isOpen}
			onClose={closeModal}
		>
			<ModalTitle data-cy="assure-savings-modal-title">
				{t(`title.${scenario}`)}
			</ModalTitle>
			<Formik
				initialValues={formFields}
				onSubmit={onAssure}
				validationSchema={hasScenario && validationSchema}
			>
				{({ isSubmitting, isValid, dirty }) => (
					<Form>
						<ModalContent>
							<TextRegular data-cy="assure-savings-modal-description">
								{t("description")}
							</TextRegular>
							<div className="flex flex-col gap-y-6 mt-24px">
								<DetailsCard data-cy="assure-savings-modal-cycle">
									<DetailsCardHeader data-cy="assure-savings-modal-cycle-title">
										{t("cycleTitle")}
									</DetailsCardHeader>
									<div
										data-cy="assure-savings-modal-cycle-data"
										className="flex gap-x-6"
									>
										<Label
											data-cy="cycle-id"
											title={t("cycleId")}
										>
											#{cycle}
										</Label>
										<Label
											data-cy="cycle-date"
											title={t("cycleDate")}
										>
											{formatTimestamp(shipmentDate)}
										</Label>
										<Label
											data-cy="plant"
											title={t("plant")}
										>
											{plant}
										</Label>
									</div>
								</DetailsCard>
								<DetailsCard data-cy="assure-savings-modal-lco2">
									<DetailsCardHeader data-cy="assure-savings-modal-lco2-title">
										{t("lco2PumpedTitle")}
									</DetailsCardHeader>
									<div
										data-cy="assure-savings-modal-lco2-data"
										className="flex gap-x-6"
									>
										<Kpi
											data-cy="fuel-total"
											size="small"
											label={tFuel("total")}
											value={formatNumber(
												totalSavings ?? 0,
											)}
											unit={carbonSavingsUnit}
										/>
										<Kpi
											data-cy="fuel-biogenic"
											size="small"
											label={tFuel("biogenic")}
											value={formatNumber(
												totalBiogenic ?? 0,
											)}
											unit={carbonSavingsUnit}
										/>
										<Kpi
											data-cy="fuel-fossil"
											size="small"
											label={tFuel("fossil")}
											value={formatNumber(
												totalFossil ?? 0,
											)}
											unit={carbonSavingsUnit}
										/>
									</div>
									<div className="mt-3">
										<Label
											data-cy="assure-savings-modal-lco2-timestamp"
											title={t("timestamp")}
										>
											{formatTimestamp(
												co2PumpedIntoStorageDate,
											)}
										</Label>
									</div>
								</DetailsCard>
							</div>

							{hasScenario && (
								<div className="mt-24px">
									{<FieldsComponent />}
								</div>
							)}
						</ModalContent>

						<ModalFooter data-cy="assure-savings-modal-actions">
							<Button
								data-cy="assure-savings-modal-actions-cancel"
								size="large"
								variant="secondary"
								onClick={closeModal}
								disabled={isSubmitting}
							>
								{tText("cancel")}
							</Button>
							<Button
								data-cy="assure-savings-modal-actions-assure"
								size="large"
								variant={submitButtonVariant}
								disabled={
									isSubmitting ||
									(hasScenario && (!isValid || !dirty))
								}
							>
								{tText(submitButtonLabel)}
							</Button>
						</ModalFooter>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}
