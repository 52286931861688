import { carbonBankWithAuthClient } from "../client"
import { generateGetUrl } from "../utils"
import {
	CycleSearchParams,
	CycleShortViewData,
	Cycle,
	ChangeCycleStatus,
} from "./types"

const PAGE_SIZE = 10

export const cycleApi = {
	getCycleShortViewData: (
		params: CycleSearchParams,
	): Promise<CycleShortViewData> =>
		carbonBankWithAuthClient
			.get(
				generateGetUrl(`/plants/${params.plantNumber}/cycles`, {
					page: params.page,
					pageSize: PAGE_SIZE,
				}),
			)
			.then(response => response.data),
	getCycleById: (plantNumber: string, id: string): Promise<Cycle> =>
		carbonBankWithAuthClient
			.get(`/plants/${plantNumber}/cycles/${id}`)
			.then(response => response.data),
	changeCycleStatus: (
		plantNumber: string,
		data: ChangeCycleStatus,
	): Promise<void> =>
		carbonBankWithAuthClient.put(`/plants/${plantNumber}/cycles`, data),
}
