import {
	authenticationApi,
	DecodedToken,
	LoginState,
	User,
	UserPermission,
	UserRole,
} from "@carbonbank/api"
import {
	getLoginState,
	startLoginProcess,
	startLogoutProcess,
} from "@carbonbank/utils/login-flow"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	decodedToken?: DecodedToken
	loginState: LoginState
	userData?: User
	userRoles?: UserRole[]
	userPermissions?: UserPermission[]
}

type Actions = {
	logout: () => Promise<void>
	login: () => Promise<void>
	checkLoginState: () => Promise<void>
	fetchUserProfile: () => Promise<void>
	fetchUserRoles: () => Promise<UserRole[] | undefined>
	fetchUserPermissions: () => Promise<UserPermission[] | undefined>
	updateUserProfile: (patch: Partial<User>) => Promise<void>
}

export const useAuthStore = create<State & Actions>()(
	immer((set, get) => ({
		decodedToken: undefined,
		loginState: { loggedIn: false },
		userData: undefined,
		userRoles: undefined,
		userPermissions: undefined,

		logout: async () => {
			startLogoutProcess()
			set(state => {
				state.decodedToken = undefined
				state.userData = undefined
				state.loginState = { loggedIn: false }
			})
		},
		login: async () => {
			const loginResponse = await getLoginState()
			if (loginResponse.loggedIn) {
				set(state => {
					state.decodedToken = loginResponse.decodedToken
					state.loginState = loginResponse
				})
			} else {
				await startLoginProcess()
			}
		},
		checkLoginState: async () => {
			const loginResponse = await getLoginState()

			if (!loginResponse.loggedIn) {
				return
			}

			set(state => {
				state.decodedToken = loginResponse.decodedToken
				state.loginState = loginResponse
			})
		},
		fetchUserProfile: async () => {
			if (get().decodedToken) {
				const response = await authenticationApi.getUserById(
					get().decodedToken!.user_id,
				)

				set(state => {
					state.userData = response
				})
			}
		},
		fetchUserRoles: async () => {
			if (get().decodedToken) {
				const response = await authenticationApi.getRolesByUserId(
					get().decodedToken!.user_id,
				)

				set(state => {
					state.userRoles = response
				})
			}

			return get().userRoles
		},
		fetchUserPermissions: async () => {
			if (get().decodedToken) {
				const response = await authenticationApi.getPermissionsByUserId(
					get().decodedToken!.user_id,
				)

				response.forEach(permission => {
					const dataScope = permission.dataScope as string
					permission.dataScope = JSON.parse(dataScope)
				})

				set(state => {
					state.userPermissions = response
				})
			}

			return get().userPermissions
		},
		updateUserProfile: async (patch: Partial<User>) => {
			if (get().decodedToken) {
				const response = await authenticationApi.updateById(
					get().decodedToken!.user_id,
					patch,
				)

				set(state => {
					state.userData = response
				})
			}
		},
	})),
)
