import { icon, PillType } from "@sustainability/fundamental"

export const pillIcon = {
	claimed: icon.mdiCheck,
	partiallyClaimed: icon.mdiTimerSandComplete,
	pending: icon.mdiTimerSandEmpty,
}

export const pillColor: Record<string, PillType> = {
	claimed: "positive",
	partiallyClaimed: "positive",
	pending: "neutral",
}
