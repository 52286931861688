import { supportedLanguages } from "@shared/i18n"
import { resolveToSupportedLanguage } from "@shared/i18n"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
	createBrowserRouter,
	RouteObject,
	RouterProvider,
} from "react-router-dom"

import { UserPermission } from "../api"
import { LoadingPage } from "../components"
import { commonRoutes } from "../pages"
import { carbonBankRoutes } from "../router"
import { useAuthStore } from "../store"
import { useUserSettingsStore } from "../store/useUserSettingsStore"
import {
	markAllRoutesAsUnavailable,
	markUnavailableRoutesByPermissionTypes,
} from "../utils/routesUtils"

const CarbonBankRouter: React.FC = () => {
	const { i18n } = useTranslation()

	const [currentLanguage, updateLanguage] = useUserSettingsStore(state => [
		state.currentLanguage,
		state.updateLanguage,
	])

	const [
		decodedToken,
		userData,
		fetchUserPermissions,
		fetchUserRoles,
		fetchUserProfile,
	] = useAuthStore(state => [
		state.decodedToken,
		state.userData,
		state.fetchUserPermissions,
		state.fetchUserRoles,
		state.fetchUserProfile,
	])

	const [carbonBankRouter, setCarbonBankRouter] = useState(
		createBrowserRouter([...commonRoutes]),
	)
	const [isLoading, setLoading] = useState(true)

	const checkRoutes = (
		routes: RouteObject[],
		permissions?: UserPermission[],
	) => {
		if (!permissions) {
			return
		}

		const permissionTypes = permissions.map(x => x.permissionType)
		markUnavailableRoutesByPermissionTypes(routes, permissionTypes)
	}

	const finishRouterSetup = (routes: RouteObject[]) => {
		const router = createBrowserRouter(routes)

		setCarbonBankRouter(router)
		setLoading(false)
	}

	useEffect(() => {
		setLoading(true)

		const routes = carbonBankRoutes.map(x => ({ ...x }))

		if (!decodedToken) {
			markAllRoutesAsUnavailable(routes)
			finishRouterSetup(routes)
			return
		}

		Promise.all([
			fetchUserPermissions().then(permissions =>
				checkRoutes(routes, permissions),
			),
			fetchUserRoles(),
			fetchUserProfile(),
		]).finally(() => finishRouterSetup(routes))
	}, [decodedToken])

	useEffect(() => {
		const setInitialLanguage = () => {
			if (userData?.defaultLocale) {
				updateLanguage(userData.defaultLocale)
			} else {
				const resolvedLanguage = resolveToSupportedLanguage(
					navigator.language,
					supportedLanguages,
				)
				updateLanguage(resolvedLanguage || supportedLanguages[0])
			}
		}

		const changeLanguage = () => {
			if (currentLanguage) {
				i18n.changeLanguage(currentLanguage)
			}
		}

		setInitialLanguage()
		changeLanguage()
	}, [userData, currentLanguage, i18n, updateLanguage])

	return (
		<>
			{isLoading ? (
				<LoadingPage />
			) : (
				<RouterProvider router={carbonBankRouter} />
			)}
		</>
	)
}

export default CarbonBankRouter
