import { NoContracts as NoMaterialsImage } from "@carbonbank/assets"
import { ContainerBox } from "@carbonbank/components"
import { H1, TextLong } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

export const NoMaterials: React.FC = () => {
	const { t } = useTranslation("features", {
		keyPrefix: "materialsList.table.noMaterials",
	})

	return (
		<ContainerBox
			data-cy="no-materials"
			className="flex flex-col justify-center items-center mt-8"
			withBottomMargin
		>
			<img
				src={NoMaterialsImage}
				alt="No Contracts"
				className="w-[24Opx] h-[154px]"
			/>
			<div className="flex flex-col items-center gap-6 mt-8 w-[400px]">
				<H1>{t("title")}</H1>
				<TextLong className="text-center text-foreground">
					{t("description")}
				</TextLong>
			</div>
		</ContainerBox>
	)
}
