import { useLoadingStore } from "@shared/stores"
import { useProjectDetailCertificates } from "@shared/stores"
import { FeatureToLoading } from "@shared/stores/loading/types"
import { NoProjects, RoundedBox } from "@shared/ui"
import { CardHeader, Table } from "@sustainability/fundamental"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useTable } from "../ui/useTable"

interface CertificatesListProps {
	pageName: string
	projectId: string
	isLoading: boolean
}

export const CertificatesList: React.FC<CertificatesListProps> = ({
	pageName,
	projectId,
	isLoading,
}) => {
	const FEATURE_NAME = "projectDetailCeritificates"
	const featureToLoading: FeatureToLoading = {
		page: pageName,
		feature: FEATURE_NAME,
	}

	const { t } = useTranslation("features", { keyPrefix: FEATURE_NAME })

	const { t: tTableHeaders } = useTranslation("features", {
		keyPrefix: `${FEATURE_NAME}.table`,
	})

	const tableConfig = useTable(tTableHeaders)

	const [certificates, fetchCertificatesForProjectDetail, resetStore] =
		useProjectDetailCertificates(state => [
			state.certificates,
			state.fetchCertificatesForProjectDetail,
			state.resetStore,
		])

	const [addFeatureToLoading, removeFeatureFromLoading] = useLoadingStore(
		state => [state.addFeatureToLoading, state.removeFeatureFromLoading],
	)

	const fetchCertificatesForProjectDetailData = async () => {
		addFeatureToLoading(featureToLoading)

		await fetchCertificatesForProjectDetail(projectId)

		removeFeatureFromLoading(featureToLoading)
	}

	useEffect(() => {
		fetchCertificatesForProjectDetailData()

		return () => resetStore()
	}, [])

	if (isLoading) {
		return <></>
	}

	return (
		<RoundedBox
			data-cy="certificates-list-for-project-container"
			className="py-4 sm:py-6"
		>
			<CardHeader
				data-cy="certificates-list-for-project-header"
				className="pl-4 md:pl-6"
			>
				{t("title")}
			</CardHeader>
			{certificates.length > 0 ? (
				<div className="md:px-3">
					<Table
						data-cy="certificates-list-for-project-table"
						config={tableConfig}
						data={certificates}
						breakpoint="lg"
					/>
				</div>
			) : (
				<div className="flex justify-center">
					<NoProjects />
				</div>
			)}
		</RoundedBox>
	)
}
