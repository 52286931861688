import React from "react"
import ReactDOM from "react-dom/client"

import CarbonBankApp from "./CarbonBankApp"
import "./index.css"
import "./shared/i18n"

const container = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
)

container.render(
	<React.StrictMode>
		<CarbonBankApp />
	</React.StrictMode>,
)
