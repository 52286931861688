import { Unit } from "@shared/api/types"

export enum CertificateType {
	CustomerProject = "customerProject",
	InternalProject = "internalProject",
}

export type SelectionOfCertificateTypeFormState = {
	certificateType: CertificateType
	projectId?: string
	project: string
}

export type SelectionOfCertificateFormInputs = {
	certificateType: CertificateType
	projectId?: string
	project?: string
	newProject?: string
}

export type SelectionOfMaterialFormState = {
	materialNumber: string
	materialDescription: string
	customerId: string
	quantity: number
	totalQuantity: number
	savings: number
	unit: Unit
}

export type SelectionOfMaterialFormInputs = {
	material: string
	materialDescription: string
	customerId: string
	quantity: number
	totalQuantity: number
	savings: number
	unit: Unit
}

export type CertificateDetailsFormState = {
	project: string
	companyName: string
	street: string
	zipCode: string
	city: string
	country: string
}

export type CertificateDetailsFormInputs = {
	project?: string
	companyName?: string
	street?: string
	zipCode?: string
	city?: string
	country?: string
}
