import { materialsApi } from "@shared/api/materials"
import { spawnErrorToast } from "@sustainability/fundamental"
import { AxiosError } from "axios"
import i18next from "i18next"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import {
	formatContractToInvoicedQuantityBarData,
	formatMaterialForTransferToRemainForCertificateCreationKpiData,
	mapMaterialToMaterialDetailsHeaderData,
} from "../utils"
import {
	FormattedInvoicedQuantityBarData,
	FormattedRemainForCertificateCreationKpiData,
	MaterialDetailsHeaderData,
} from "./types"

type State = {
	materialHeaderData?: MaterialDetailsHeaderData
	invoicedQuantityBarData?: FormattedInvoicedQuantityBarData
	remainForCertificateCreationKpiData?: FormattedRemainForCertificateCreationKpiData
	isAvailableQuantityForTransfer: boolean
}

type Actions = {
	fetchMaterial: (
		contractNumber: string,
		materialNumber: string,
	) => Promise<void>
	resetStore: () => void
}

export const useMaterialDetailsHeaderStore = create<State & Actions>()(
	immer(set => ({
		materialHeaderData: undefined,
		invoicedQuantityBarData: undefined,
		remainForCertificateCreationKpiData: undefined,
		isAvailableQuantityForTransfer: false,

		fetchMaterial: async (
			contractNumber: string,
			materialNumber: string,
		) => {
			try {
				const response = await materialsApi.getById(
					contractNumber,
					materialNumber,
				)

				const mappedMaterialHeaderData =
					mapMaterialToMaterialDetailsHeaderData(response)
				const formattedInvoicedQuantityBarData =
					formatContractToInvoicedQuantityBarData(response.contract)
				const formattedRemainForCertificateCreationKpiData =
					formatMaterialForTransferToRemainForCertificateCreationKpiData(
						response.availableForTransfer,
					)

				set(() => ({
					materialHeaderData: mappedMaterialHeaderData,
					invoicedQuantityBarData: formattedInvoicedQuantityBarData,
					remainForCertificateCreationKpiData:
						formattedRemainForCertificateCreationKpiData,
					isAvailableQuantityForTransfer:
						response.availableForTransfer.material.amount > 0,
				}))
			} catch (error) {
				spawnErrorToast(
					i18next.t("features:materialDetailsHeader.errorMessage"),
				)

				const axiosError = error as AxiosError
				const isNotFoundError =
					axiosError &&
					axiosError.response &&
					axiosError.response.status === 404

				if (isNotFoundError) {
					window.location.href = "/not-found"
				}
			}
		},
		resetStore: () => {
			set(() => ({
				materialHeaderData: undefined,
				invoicedQuantityBarData: undefined,
				remainForCertificateCreationKpiData: undefined,
				isAvailableQuantityForTransfer: false,
			}))
		},
	})),
)
