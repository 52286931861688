import { RoundedBox, SectionInfoHeader } from "@shared/ui"
import { clx, ExternalLink, TextLong } from "@sustainability/fundamental"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { DnvInfoModal } from "./dnv-info-modal"
import { DnvLogo } from "./dnv-logo"

interface DnvCarbonSavingsCardProps {
	className?: string
	isLoading: boolean
}

export const DnvCarbonSavingsCard: React.FC<DnvCarbonSavingsCardProps> = ({
	className,
	isLoading,
}) => {
	const { t } = useTranslation("shared", {
		keyPrefix: "dnvCarbonSavingsCard",
	})

	const [isModalOpen, setModalOpen] = useState(false)

	if (isLoading) {
		return <></>
	}

	return (
		<>
			<RoundedBox
				data-cy="dnv-carbon-savings-card-container"
				className={clx(
					"flex md:items-center md:gap-x-[24px] md:flex-row xs:items-start xs:flex-col-reverse p-4 md:p-6",
					className,
				)}
			>
				<DnvLogo />
				<div className="flex flex-col md:mb-0 xs:w-full xs:mb-[12px]">
					<SectionInfoHeader
						data-cy="dnv-carbon-savings-card-header"
						data-cy-button="dnv-info-modal-button"
						title={t("title")}
						buttonTitle={t("buttonTitle")}
						onClick={() => setModalOpen(true)}
					/>
					<div data-cy="dnv-carbon-savings-card-description-container">
						<TextLong className="text-foreground_faded">
							{t("description")}
							<ExternalLink>{t("linkTitle")}</ExternalLink>
						</TextLong>
					</div>
				</div>
			</RoundedBox>
			<DnvInfoModal
				isModalOpen={isModalOpen}
				onClose={() => setModalOpen(false)}
			/>
		</>
	)
}
