import { CertificateType } from "@shared/stores"
import { CardHeader, Radio, RadioGroup } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface SelectTheCertificateTypeSectionProps {
	featureName: string
}

export const SelectTheCertificateTypeSection: React.FC<
	SelectTheCertificateTypeSectionProps
> = ({ featureName }) => {
	const { t } = useTranslation("features", {
		keyPrefix: `${featureName}.content.selectTheCertificateTypeSection`,
	})

	return (
		<div>
			<CardHeader>{t("title")}</CardHeader>
			<RadioGroup className="[&>tr]:border-b-solid [&>tr]:border-b-[1px]">
				<Radio
					label={t("customerProject.title")}
					subLabel={t("customerProject.description")}
					name="certificateType"
					value={CertificateType.CustomerProject}
					defaultChecked
				/>
				<Radio
					label={t("internalProjectOrProduct.title")}
					subLabel={t("internalProjectOrProduct.description")}
					name="certificateType"
					value={CertificateType.InternalProject}
				/>
			</RadioGroup>
		</div>
	)
}
