import {
	certificateApi,
	transferApi,
	TransferCertificate,
	VerificationDetails,
} from "@carbonbank/api"
import { depositApi } from "@carbonbank/api/deposit"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	totalCarbonCaptured?: number
	certificate?: TransferCertificate
	verified: boolean
	verificationDetails?: VerificationDetails
	isLoading: boolean
	error: string | null
}
type Actions = {
	fetchTotalCarbonCaptured: () => Promise<void>
	fetchTransactionDetails: (id: string) => Promise<void>
	verifyTransaction: (
		transactionId: string,
		verificationNumber: string,
	) => Promise<void>
}

export const useTransactionStore = create<State & Actions>()(
	immer(set => ({
		totalCarbonCaptured: undefined,
		transactionDetails: undefined,
		verificationDetails: undefined,
		verified: false,
		isLoading: true,
		error: null,
		fetchTotalCarbonCaptured: async () => {
			try {
				set(state => {
					state.isLoading = true
				})
				const response = await depositApi.getDeposit()
				set(state => {
					state.totalCarbonCaptured = response.totalDeposits
					state.isLoading = false
				})
			} catch (error) {
				set(state => {
					state.error = "Failed to fetch total deposit"
					state.isLoading = false
				})
				spawnErrorToast("Failed to fetch total deposit")
			}
		},
		fetchTransactionDetails: async (id: string) => {
			try {
				set(state => {
					state.isLoading = true
				})
				const response = await transferApi.getTransferCertificate(id)
				set(state => {
					state.certificate = response
					state.isLoading = false
				})
			} catch (error) {
				set(state => {
					state.error = "Failed to fetch transaction details"
					state.isLoading = false
				})
				spawnErrorToast("Failed to fetch transactions detauls")
			}
		},
		verifyTransaction: async (
			transactionId: string,
			verificationNumber: string,
		) => {
			try {
				const payload = {
					transactionId,
					verificationNumber,
				}
				const response = await certificateApi.verify(payload)
				set(state => {
					state.verified = true
					state.verificationDetails = response
				})
			} catch (error) {
				spawnErrorToast(
					"The transaction could not be verified. Please check the verification ID",
				)
			}
		},
	})),
)
