import CementManufacturing from "@shared/assets/cementManufacturing.png"
import ConcreteProduct from "@shared/assets/concreteProduct.png"
import DnvAssuranceTransaction from "@shared/assets/dnvAssuranceTransaction.png"
import FinalOwner from "@shared/assets/finalOwner.png"
import PermanentStorage from "@shared/assets/permanentStorage.png"
import ReceivingTerminal from "@shared/assets/receivingTerminal.png"
import TemporaryStorage from "@shared/assets/temporaryStorage.png"
import TransportPreparation from "@shared/assets/transportPreparation.png"
import TransportViaShip from "@shared/assets/transportViaShip.png"

// TODO Check images, move images to some module?
const translationPrefix = "features:activityHistory.item"

export const ActivityHistoryStepContent: Record<
	string,
	{
		title: string
		description: string
		image: string
	}
> = {
	produced: {
		title: `${translationPrefix}.firstTitle`,
		image: CementManufacturing,
		description: `${translationPrefix}.firstDescription`,
	},
	stored: {
		title: `${translationPrefix}.secondTitle`,
		image: TemporaryStorage,
		description: `${translationPrefix}.secondDescription`,
	},
	delivered: {
		title: `${translationPrefix}.thirdTitle`,
		image: TransportPreparation,
		description: `${translationPrefix}.thirdDescription`,
	},
	ship: {
		title: `${translationPrefix}.fourthTitle`,
		image: TransportViaShip,
		description: `${translationPrefix}.fourthDescription`,
	},
	terminal: {
		title: `${translationPrefix}.fifthTitle`,
		image: ReceivingTerminal,
		description: `${translationPrefix}.fifthDescription`,
	},
	permanent_storage: {
		title: `${translationPrefix}.sixthTitle`,
		image: PermanentStorage,
		description: `${translationPrefix}.sixthDescription`,
	},
	deposits: {
		title: `${translationPrefix}.seventhTitle`,
		image: DnvAssuranceTransaction,
		description: `${translationPrefix}.seventhDescription`,
	},
	invoices: {
		title: `${translationPrefix}.eighthTitle`,
		image: ConcreteProduct,
		description: `${translationPrefix}.eighthDescription`,
	},
	transfers: {
		title: `${translationPrefix}.ninethTitle`,
		image: FinalOwner,
		description: `${translationPrefix}.ninethDescription`,
	},
}
