import { Yup } from "@carbonbank/yup"
import i18next from "@shared/i18n"

export const getAssureValidationSchema = () =>
	Yup.object().shape({
		serialNumber: Yup.string()
			.dnvSerialFormat()
			.required(() => i18next.t("cb:common.validation.required")),
	})

export const getRequestInformationValidationSchema = () =>
	Yup.object().shape({
		comment: Yup.string().required(() =>
			i18next.t("common.validation.required"),
		),
	})

export const getRejectValidationSchema = () =>
	Yup.object().shape({
		comment: Yup.string().required(() =>
			i18next.t("common.validation.required"),
		),
	})
