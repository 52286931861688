import { icon, Icon, useSortableTable } from "@sustainability/fundamental"
import { TFunction } from "i18next"
import { Link } from "react-router-dom"

import { FormattedInvoicesForMaterialItem } from "../../store/types"

const COMMON_VALUE_COLUMNS_CLASS_NAME =
	"whitespace-nowrap overflow-hidden text-ellipsis"

export const useInvoicesTableConfig = (t: TFunction<"features", string>) =>
	useSortableTable<FormattedInvoicesForMaterialItem>([
		{
			id: "createdOn",
			label: t("date"),
			sortable: false,
			render: ({ createdOn }) => (
				<div className={COMMON_VALUE_COLUMNS_CLASS_NAME}>
					{createdOn}
				</div>
			),
			valueClassName: "lg:w-[172px] xl:w-[186px]",
			headerClassName: "lg:w-[172px] xl:w-[186px]",
		},
		{
			id: "invoiceNumber",
			label: t("invoiceNumber"),
			sortable: false,
			render: ({ invoiceNumber }) => (
				<div className={COMMON_VALUE_COLUMNS_CLASS_NAME}>
					{invoiceNumber}
				</div>
			),
			valueClassName: `lg:w-[180px] xl:w-[364px]`,
			headerClassName: `lg:w-[180px] xl:w-[364px]`,
		},
		{
			id: "deliveredQuantity",
			label: t("deliveredQuantity"),
			sortable: false,
			render: ({ deliveredQuantity }) => (
				<div className={COMMON_VALUE_COLUMNS_CLASS_NAME}>
					{deliveredQuantity}
				</div>
			),
			valueClassName: "lg:text-right lg:w-[180px]xl:w-[197px]",
			headerClassName: "lg:text-right lg:w-[180px] xl:w-[197px]",
		},
		{
			id: "bundledCarbonSavings",
			label: t("bundledCarbonSavings"),
			sortable: false,
			render: ({ bundledCarbonSavings }) => (
				<div className={COMMON_VALUE_COLUMNS_CLASS_NAME}>
					{bundledCarbonSavings}
				</div>
			),
			valueClassName: "lg:text-right lg:w-[180px] xl:w-[197px]",
			headerClassName: "lg:text-right lg:w-[180px] xl:w-[197px]",
		},
		{
			id: "transactionId",
			label: t("transactionId"),
			sortable: false,
			render: ({ transactionId }) => (
				<div className={COMMON_VALUE_COLUMNS_CLASS_NAME}>
					{transactionId}
				</div>
			),
			valueClassName: "lg:w-[180px] xl:w-[364px]",
			headerClassName: "lg:w-[180px] xl:w-[364px]",
		},
		{
			label: undefined,
			sortable: false,
			render: ({ transactionLink }) => (
				<Link to={transactionLink} className="inline-block">
					<Icon path={icon.mdiChevronRight} />
				</Link>
			),
			valueClassName: "lg:w-[60px]",
			headerClassName: "lg:w-[60px]",
		},
	])
