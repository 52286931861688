import { CardHeader, TextRegular } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface CertificateDetailsSectionProps {
	featureName: string
}

export const CertificateDetailsSection: React.FC<
	CertificateDetailsSectionProps
> = ({ featureName }) => {
	const { t } = useTranslation("features", {
		keyPrefix: `${featureName}.content.certificateDetailsSection`,
	})

	return (
		<div>
			<CardHeader>{t("title")}</CardHeader>
			<TextRegular className="text-foreground_faded">
				{t("description")}
			</TextRegular>
		</div>
	)
}
