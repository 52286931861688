import { clx, useMediaQuery } from "@sustainability/fundamental"
import { Form } from "formik"
import { ReactNode } from "react"

import { FormSummary } from "./form-summary"

interface FormWithSummaryProps {
	"data-cy": string
	formContent: ReactNode
	summaryContent: ReactNode
	isValid: boolean
	summaryTitle: string
	submitTitle: string
	submitIcon: string
	backTitle?: string
	onBack?: () => void
}

export const FormWithSummary: React.FC<FormWithSummaryProps> = ({
	"data-cy": dataCy,
	formContent,
	summaryContent,
	isValid,
	summaryTitle,
	submitTitle,
	submitIcon,
	backTitle,
	onBack,
}) => {
	const isLessThanMediumScreen = useMediaQuery({
		breakpoint: "lg",
		upTo: true,
	})

	return (
		<Form data-cy={dataCy}>
			<div
				data-cy={`${dataCy}-container`}
				className="flex max-lg:flex-col lg:gap-[48px]"
			>
				<div
					data-cy={`${dataCy}-content-container`}
					className={clx(
						"h-auto max-lg:w-full xl:w-[calc(61%-24px)] lg:w-[calc(58.6%-24px)]",
						isLessThanMediumScreen && "mb-[185px]",
					)}
				>
					{formContent}
				</div>
				<FormSummary
					dataCy={dataCy}
					summaryTitle={summaryTitle}
					summaryContent={summaryContent}
					submitTitle={submitTitle}
					submitIcon={submitIcon}
					backTitle={backTitle}
					onBack={onBack}
					isLessThanMediumScreen={isLessThanMediumScreen}
					isValid={isValid}
				/>
			</div>
		</Form>
	)
}
