import {
	cycleApi,
	ChangeCycleStatus,
	CreateCommentRequest,
	commentsApi,
	CycleShortView,
	Cycle,
} from "@carbonbank/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	data?: Cycle | CycleShortView
	isOpen: boolean
	scenario: ScenarioVariant
}

type Actions = {
	openModal: (
		data: Cycle | CycleShortView,
		scenario: State["scenario"],
	) => void
	closeModal: () => void
	submitData: (
		plantNumber: string,
		data: ChangeCycleStatus,
		comment?: CreateCommentRequest,
	) => Promise<void>
	getScenario: () => ScenarioVariant
}

export type ScenarioVariant = "assure" | "reject" | "requestInformation"

export const useCarbonSavingsModalStore = create<State & Actions>()(
	immer((set, get) => ({
		isOpen: false,
		scenario: "assure",
		data: undefined,

		openModal: (data, scenario) => {
			set(state => {
				state.data = data
				state.scenario = scenario
				state.isOpen = true
			})
		},

		closeModal: () => {
			set(state => {
				state.isOpen = false
			})
		},

		submitData: async (
			plantNumber: string,
			data: ChangeCycleStatus,
			comment?: CreateCommentRequest,
		) => {
			try {
				if (!data.cycle || !plantNumber) {
					return
				}

				await Promise.all([
					cycleApi.changeCycleStatus(plantNumber, data),
					comment
						? commentsApi.createComment(comment)
						: Promise.resolve(),
				])

				get().closeModal()

				set(state => {
					if (state.data?.status) {
						state.data.status = data.status
					}
				})
			} catch (e) {
				spawnErrorToast("Failed to modify cycle status")
			}
		},

		getScenario: () => {
			return get().scenario ?? "assure"
		},
	})),
)
