import { certificatesApi, PreviousProject } from "@shared/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import i18next from "i18next"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	projects?: PreviousProject[]
}

type Actions = {
	fetchPreviousProjects: () => Promise<void>
}

export const useSelectionOfCertificateTypeFormStore = create<State & Actions>()(
	immer(set => ({
		projects: undefined,

		fetchPreviousProjects: async () => {
			try {
				const projectData = await certificatesApi.getPreviousProjects()

				set(state => {
					state.projects = projectData.projects
				})
			} catch {
				spawnErrorToast(
					i18next.t(
						"features:selectionOfCertificateTypeForm.errorMessages.fetchProjectsError",
					),
				)
			}
		},
	})),
)
