import i18next from "@shared/i18n"
import * as Yupi from "yup"

declare module "yup" {
	interface StringSchema {
		dnvSerialFormat(errorMessage?: string): StringSchema
		transactionIdFormat(errorMessage?: string): StringSchema
	}

	interface BooleanSchema {
		requireChecked(errorMessage?: string): BooleanSchema
	}
}

Yupi.addMethod(Yupi.boolean, "requireChecked", function (message?: string) {
	return this.test(
		"requireChecked",
		() => message ?? i18next.t("cb:common.validation.requireChecked"),
		function (value) {
			return !!value
		},
	)
})

Yupi.addMethod(Yupi.string, "dnvSerialFormat", function (message?: string) {
	return this.test(
		"requireDnvSerialFormat",
		() =>
			message ?? i18next.t("cb:common.validation.requireDnvSerialFormat"),
		function (value) {
			return !!value && /^DNV-[0-9]{4}-[A-Z]{3}-[0-9]{6}$/.test(value)
		},
	)
})

Yupi.addMethod(Yupi.string, "transactionIdFormat", function (message?: string) {
	return this.test(
		"requireTransactionIdFormat",
		() =>
			message ??
			i18next.t(
				"transactions:transactions.components.showTransactions.transactionNumberValidation",
			),
		function (value) {
			return !!value && /^\d\.\d\.\d{7}@\d{10}\.\d{9}$/.test(value)
		},
	)
})

Yupi.addMethod(Yupi.string, "projectReference", function (message?: string) {
	return this.test(
		"requiredProjectReferenceFormat",
		() =>
			message ??
			i18next.t(
				"transactions:transactions.components.showTransactions.transactionNumberValidation",
			),
		function (value) {
			return !!value && /^\d\.\d\.\d{7}@\d{10}\.\d{9}$/.test(value)
		},
	)
})

export const Yup = Yupi
