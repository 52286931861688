import {
	cycleApi,
	Cycle,
	CycleSearchParams,
	CycleShortView,
} from "@carbonbank/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { CycleStats } from "./types"

const DEFAULT_PAGE = 0

type State = {
	cycleShortViews: CycleShortView[]
	nextSavingsPage: number
	cycle?: Cycle
	cycleStats?: CycleStats
	hasMoreSavingsData: boolean
	isLoadingCarbonSavings: boolean
}
type Actions = {
	resetStore: () => void
	fetchCycleShortViewData: (filters: CycleSearchParams) => Promise<void>
	fetchCycleById: (plantNumber: string, id: string) => Promise<void>
}

export const useCarbonSavingsStore = create<State & Actions>()(
	immer((set, get) => ({
		cycleShortViews: [],
		nextSavingsPage: 1,
		cycle: undefined,
		cycleStats: undefined,
		hasMoreSavingsData: false,
		isLoadingCarbonSavings: false,

		resetStore: () => {
			set(state => {
				state.cycleShortViews = []
				state.nextSavingsPage = 1
				state.cycle = undefined
				state.cycleStats = undefined
				state.hasMoreSavingsData = false
				state.isLoadingCarbonSavings = false
			})
		},
		fetchCycleShortViewData: async (filters: CycleSearchParams) => {
			if (!filters.plantNumber) return

			const isDefaultPage = filters.page === DEFAULT_PAGE

			if (isDefaultPage) {
				set(state => {
					state.cycleShortViews = []
					state.nextSavingsPage = filters.page!
				})
			}

			filters.page = get().nextSavingsPage

			try {
				set(state => {
					state.isLoadingCarbonSavings = true
				})

				const response = await cycleApi.getCycleShortViewData(filters)

				set(state => {
					if (isDefaultPage) {
						state.cycleShortViews = response.cycles
					} else {
						state.cycleShortViews = [
							...get().cycleShortViews,
							...response.cycles,
						]
					}
					state.hasMoreSavingsData = response.cycles.length > 0
					state.cycleStats = { ...response }
					state.nextSavingsPage = ++filters.page!
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch carbon savings")
			} finally {
				set(state => {
					state.isLoadingCarbonSavings = false
				})
			}
		},
		fetchCycleById: async (plantNumber: string, id: string) => {
			try {
				const response = await cycleApi.getCycleById(plantNumber, id)

				set(state => {
					state.cycle = response
				})
			} catch (error) {
				spawnErrorToast("Failed to carbon savings cycle")
			} finally {
				set(state => {
					state.isLoadingCarbonSavings = false
				})
			}
		},
	})),
)
