import MicroiteMain from "./MicrositeMain.png"
import HmLogo from "./hmLogo.png"
import de from "./locales/de-DE.json"
import en from "./locales/en-GB.json"
import MicroSiteEvoZero from "./microSiteLogo.png"

export const MicroSiteLogo = MicroSiteEvoZero
export const MicroSiteMainImage = MicroiteMain
export const HmLogoImage = HmLogo

export const microsite = {
	en,
	de,
}
