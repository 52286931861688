import { useCertificateCreationFormStore } from "@carbonbank/shared/stores"
import {
	Alert,
	Button,
	icon,
	LabelRegular,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextMedium,
	TextRegular,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface ReviewCertificateDetailsModalProps {
	featureName: string
	isModalOpen: boolean
	onSubmit: () => void
	onClose: () => void
}

export const ReviewCertificateDetailsModal: React.FC<
	ReviewCertificateDetailsModalProps
> = ({ featureName, isModalOpen, onSubmit, onClose }) => {
	const { t } = useTranslation("features", {
		keyPrefix: `${featureName}.reviewCertificateDetailsModal`,
	})

	const certificateDetailsFormState = useCertificateCreationFormStore(
		state => state.certificateDetailsFormState,
	)

	if (!certificateDetailsFormState) {
		return <></>
	}

	return (
		<Modal open={isModalOpen} onClose={onClose}>
			<ModalTitle>{t("title")}</ModalTitle>
			<ModalContent>
				<div className="flex flex-col gap-y-[24px]">
					<div>
						<TextRegular>{t("description")}</TextRegular>
					</div>
					<Alert variant="warning">{t("alertDescription")}</Alert>
					<div className="flex flex-col gap-y-[12px]">
						<div className="flex flex-col">
							<LabelRegular className="text-foreground_faded">
								{t("projectReferenceLabel")}
							</LabelRegular>
							<TextMedium>
								{certificateDetailsFormState.project}
							</TextMedium>
						</div>
						<div className="flex flex-col">
							<LabelRegular className="text-foreground_faded">
								{t("companyNameLabel")}
							</LabelRegular>
							<TextMedium>
								{certificateDetailsFormState.companyName}
							</TextMedium>
						</div>
						<div className="flex flex-col">
							<LabelRegular className="text-foreground_faded">
								{t("streetLabel")}
							</LabelRegular>
							<TextMedium>
								{certificateDetailsFormState.street}
							</TextMedium>
						</div>
						<div className="flex flex-col">
							<LabelRegular className="text-foreground_faded">
								{t("zipCodeLabel")}
							</LabelRegular>
							<TextMedium>
								{certificateDetailsFormState.zipCode}
							</TextMedium>
						</div>
						<div className="flex flex-col">
							<LabelRegular className="text-foreground_faded">
								{t("cityLabel")}
							</LabelRegular>
							<TextMedium>{certificateDetailsFormState.city}</TextMedium>
						</div>
						<div className="flex flex-col">
							<LabelRegular className="text-foreground_faded">
								{t("countryLabel")}
							</LabelRegular>
							<TextMedium>
								{certificateDetailsFormState.country}
							</TextMedium>
						</div>
					</div>
				</div>
			</ModalContent>
			<ModalFooter>
				<div className="flex gap-6 max-md:flex-col max-md:w-full">
					<Button
						onClick={onClose}
						variant="secondary"
						icon={icon.mdiArrowLeft}
						type="button"
					>
						{t("backTitle")}
					</Button>
					<Button
						icon={icon.mdiCheck}
						type="button"
						onClick={onSubmit}
					>
						<div className="whitespace-nowrap overflow-hidden text-ellipsis">
							{t("submitTitle")}
						</div>
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	)
}
