import { getMaterialDetailsUrl } from "@carbonbank/shared/routes"
import { Loading, Table, useMediaQuery } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Material } from "../../../../shared/api/materials/types"
import { useTable } from "./useTable"

export type MaterialsTableProps = {
	loading: boolean
	data?: Material[]
	loadData: (loadTransferred: boolean) => Promise<void>
	canLoadMoreData: boolean
}

export const MaterialsTable: React.FC<MaterialsTableProps> = ({
	data,
	loading,
}) => {
	const navigate = useNavigate()
	const { t: tTable } = useTranslation("features", {
		keyPrefix: "materialsList.table",
	})
	const isMobile = useMediaQuery({ breakpoint: "md", upTo: true })
	const tableConfig = useTable(tTable, isMobile)

	return (
		<div className="flex justify-center w-full">
			{loading ? (
				<Loading
					data-cy="materials-list-loading-indicator"
					size="large"
				/>
			) : (
				<Table
					data-cy="materials-list-table"
					onRowClick={row =>
						navigate(
							getMaterialDetailsUrl(
								row.contract.number,
								row.itemNumber,
							),
						)
					}
					config={tableConfig}
					data={data}
				/>
			)}
		</div>
	)
}
