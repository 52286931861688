import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextRegular,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface TraceabilityModalProps {
	isModalOpen: boolean
	onClose: () => void
}

export const TraceabilityModal: React.FC<TraceabilityModalProps> = ({
	isModalOpen,
	onClose,
}) => {
	const { t } = useTranslation("features", {
		keyPrefix: "activityHistory.traceabilityModal",
	})

	return (
		<Modal
			data-cy="traceability-modal"
			className="!w-[619px]"
			open={isModalOpen}
			onClose={onClose}
		>
			<ModalTitle data-cy="traceability-modal-title" className="pt-0">
				{t("title")}
			</ModalTitle>
			<ModalContent data-cy="traceability-modal-content">
				<TextRegular className="text-foreground">
					{t("paragraph")}
				</TextRegular>
			</ModalContent>
			<ModalFooter
				data-cy="traceability-modal-footer"
				className="max-md:flex-col max-md:w-full"
			>
				<Button
					onClick={onClose}
					data-cy="traceability-modal-button-close"
				>
					{t("close")}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
