import {
	getTokenHashScanLink,
	getTransactionHashScanLink,
} from "@carbonbank/utils"

export const getProofLink = (
	activityType: "measurement" | "transaction",
	proof: string,
) =>
	activityType === "transaction"
		? getTransactionHashScanLink(proof)
		: getTokenHashScanLink(proof)
