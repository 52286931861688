import { materials, projectDetail, projects } from "@shared/routes"
import { Layout } from "@shared/ui"
import { Navigate, RouteObject } from "react-router-dom"

import { ProjectDetail } from "../project-detail/ui"

export default [
	{
		path: projects,
		element: <Layout />,
		children: [
			{
				index: true,
				element: <Navigate to={materials} />,
			},
			{
				path: projectDetail,
				element: <ProjectDetail />,
			},
		],
	},
] satisfies RouteObject[]
