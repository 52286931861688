import { CardHeader, Input, TextRegular } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface AddProjectReferenceSectionProps {
	featureName: string
}

export const AddProjectReferenceSection: React.FC<
	AddProjectReferenceSectionProps
> = ({ featureName }) => {
	const { t } = useTranslation("features", {
		keyPrefix: `${featureName}.content.addProjectReferenceSection`,
	})

	return (
		<div data-cy="add-project-reference-section-container">
			<CardHeader data-cy="add-project-reference-section-header">
				{t("title")}
			</CardHeader>
			<div
				data-cy="add-project-reference-section-content"
				className="flex flex-col gap-y-[16px] xl:w-[62.5%] lg:w-full"
			>
				<TextRegular data-cy="add-project-reference-section-subtitle">
					{t("subTitle")}
				</TextRegular>
				<Input
					data-cy="add-project-reference-section-input"
					className="[&>div>div>input]:min-h-[48px]"
					name="newProject"
					placeholder={t("projectReferencePlaceholder")}
				/>
			</div>
		</div>
	)
}
