import { LogoDnv } from "@carbonbank/assets"
import {
	Button,
	icon,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextRegular,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface DnvInfoModalProps {
	isModalOpen: boolean
	onClose: () => void
}

export const DnvInfoModal: React.FC<DnvInfoModalProps> = ({
	isModalOpen,
	onClose,
}) => {
	const { t } = useTranslation("shared", {
		keyPrefix: "dnvCarbonSavingsCard.dnvInfoModal",
	})

	return (
		<Modal
			data-cy="dnv-info-modal"
			className="!w-[600px]"
			open={isModalOpen}
			onClose={onClose}
		>
			<ModalTitle data-cy="dnv-info-modal-title">
				<div className="flex justify-between">
					{t("title")}
					<img
						data-cy="dnv-assurance-logo"
						src={LogoDnv}
						alt="DNV Assurance logo"
						className="w-[52px] h-[28px]"
					/>
				</div>
			</ModalTitle>
			<ModalContent data-cy="dnv-info-modal-content">
				<TextRegular className="block mb-[29px]">
					{t("description.paragraph1")}
				</TextRegular>
				<TextRegular className="block mb-[29px]">
					{t("description.paragraph2")}
				</TextRegular>
				<TextRegular>{t("description.paragraph3")}</TextRegular>
			</ModalContent>
			<ModalFooter data-cy="dnv-info-modal-footer">
				<div
					data-cy="dnv-info-modal-footer-container"
					className="flex gap-6 max-md:flex-col max-md:w-full"
				>
					<Button
						variant="secondary"
						icon={icon.mdiOpenInNew}
						data-cy="dnv-info-modal-show-link-button"
					>
						<div className="whitespace-nowrap overflow-hidden text-ellipsis">
							{t("showLink")}
						</div>
					</Button>
					<Button
						onClick={onClose}
						data-cy="dnv-info-modal-close-button"
					>
						{t("buttonClose")}
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	)
}
