import {
	CertificateType,
	SelectionOfCertificateFormInputs,
} from "@shared/stores"
import { Label } from "@sustainability/fundamental"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"

interface SelectionOfCertificateTypeSummaryProps {
	featureName: string
}

export const SelectionOfCertificateTypeSummary: React.FC<
	SelectionOfCertificateTypeSummaryProps
> = ({ featureName }) => {
	const { t } = useTranslation("features", {
		keyPrefix: `${featureName}.summary`,
	})

	const { t: tCertificateType } = useTranslation("features", {
		keyPrefix: `${featureName}.content.selectTheCertificateTypeSection`,
	})

	const { values } = useFormikContext<SelectionOfCertificateFormInputs>()

	const project = values.project || values.newProject || "-"

	return (
		<>
			<Label title={t("certificateTypeTitle")}>
				{values.certificateType === CertificateType.CustomerProject
					? tCertificateType("customerProject.title")
					: tCertificateType("internalProjectOrProduct.title")}
			</Label>
			<Label title={t("projectReferenceTitle")}>{project}</Label>
		</>
	)
}
