import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	currentLanguage: string | undefined
}

type Actions = {
	updateLanguage: (languageCode: string) => void
}

export const useUserSettingsStore = create<State & Actions>()(
	immer(set => ({
		currentLanguage: undefined,
		updateLanguage: (language: string) => {
			set(state => {
				state.currentLanguage = language
			})
		},
	})),
)
