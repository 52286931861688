import {
	CustomerFilterData,
	filterApi,
	Plant,
	plantApi,
	SiteFilterData,
} from "@carbonbank/api"
import { customersApi } from "@carbonbank/api/customers"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { AllocationFilterData } from "./types"

type State = {
	siteFilterData?: SiteFilterData[]
	plants?: Plant[]
	customerFilterData?: CustomerFilterData[]
	allocationFilterData?: AllocationFilterData
	selectedPlantNumber?: string
}

type Actions = {
	fetchSiteFilterData: () => Promise<void>
	fetchPlantFilterData: () => Promise<void>
	fetchCustomerFilterData: () => Promise<void>
	fetchAllocationFilterData: () => Promise<void>
	setSelectedPlantNumber: (plantNumber: string) => void
}

export const useFilterStore = create<State & Actions>()(
	immer(set => ({
		siteFilterData: undefined,
		plants: undefined,
		customerFilterData: undefined,
		allocationFilterData: undefined,
		selectedPlantNumber: undefined,

		fetchSiteFilterData: async () => {
			try {
				const response = await filterApi.fetchSiteFilterData()

				set(state => {
					state.siteFilterData = response
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch location filter data")
			}
		},
		fetchPlantFilterData: async () => {
			try {
				const response = await plantApi.getPlants()

				set(state => {
					state.plants = response
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch plant filter data")
			}
		},
		fetchCustomerFilterData: async () => {
			try {
				const response = await filterApi.fetchCustomerFilterData()

				set(state => {
					state.customerFilterData = response
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch customer filter data")
			}
		},
		fetchAllocationFilterData: async () => {
			try {
				const [siteResponse, productResponse, customerResponse] =
					await Promise.all([
						filterApi.fetchSiteFilterData(),
						filterApi.fetchProductFilterData(),
						customersApi.getCustomers(),
					])

				set(state => {
					state.allocationFilterData = {
						siteFilterData: siteResponse,
						productFilterData: productResponse,
						customerFilterData: customerResponse.customers,
					}
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch filter data")
			}
		},
		setSelectedPlantNumber: plantNumber => {
			set(state => {
				state.selectedPlantNumber = plantNumber
			})
		},
	})),
)
