import { useCarbonTransferStore, useContractsStore } from "@carbonbank/store"
import { formatNumber } from "@carbonbank/utils"
import {
	Button,
	DetailsCard,
	icon,
	Loading,
	CardHeader,
	Alert,
	Label,
	Icon,
	useMediaQuery,
	clx,
} from "@sustainability/fundamental"
import { useFormikContext } from "formik"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { TransferFormInputs } from "../../types"
import { FormValidationModal } from "../FormValidationModal"
import { SuccessModal } from "../SuccessModal"

interface CarbonTransferSummaryProps {
	isValid: boolean
}

export const CarbonTransferSummary: React.FC<CarbonTransferSummaryProps> = ({
	isValid,
}) => {
	const { t } = useTranslation("contracts", {
		keyPrefix: "carbonTransfer.components.carbonTransferSummary",
	})
	const { values, getFieldProps } = useFormikContext<TransferFormInputs>()
	const [contract] = useContractsStore(state => [state.contract])

	const [isLoading, setValidationModal] = useCarbonTransferStore(state => [
		state.isLoading,
		state.setValidationModal,
	])

	const { t: tCommon } = useTranslation("cb", {
		keyPrefix: "common",
	})

	const isLargeScreen = useMediaQuery({ breakpoint: "md", upTo: false })

	const [opened, setOpened] = useState<boolean>(false)
	const [opening, setOpening] = useState<boolean>(false)
	const contentVisible = opened || opening

	const isPartialTransfer =
		values?.cementAmount &&
		contract?.availableToTransfer &&
		values.cementAmount < contract.availableToTransfer

	const getButtonText = () => {
		if (isLoading) {
			return <Loading variant="primary" size="xsmall" />
		}

		const isFullTransfer =
			values.cementAmount === contract?.availableToTransfer

		if (values.isManualCertificate) {
			return isFullTransfer
				? t("createCertificate")
				: t("requestTransferSavings")
		}

		return isFullTransfer ? t("transfer") : t("requestTransferSavings")
	}

	const handleToggle = () => {
		setOpened(opened => !opened)
		setOpening(opening => !opening)
	}

	return (
		<>
			<DetailsCard
				data-cy="carbon-transfer-summary"
				className={clx(
					"border-none shadow-lg transition-all duration-500 h-auto",
					isLargeScreen || contentVisible ? "h-[300px]" : "h-[156px]",
				)}
			>
				<div className="px-2">
					<CardHeader className="w-full">
						<div className="flex justify-between items-center">
							{t("title")}
							{!isLargeScreen && (
								<Button
									onClick={() => {
										handleToggle()
									}}
									variant="tertiary"
								>
									{contentVisible ? (
										<Icon path={icon.mdiChevronDown} />
									) : (
										<Icon path={icon.mdiChevronUp} />
									)}
									{contentVisible && tCommon("hide")}
								</Button>
							)}
						</div>
					</CardHeader>
				</div>
				<div className="flex flex-col gap-3 px-2">
					<div
						className={
							contentVisible || isLargeScreen
								? "visible"
								: "hidden"
						}
					>
						<Label title={t("product")}>
							{getFieldProps("product").value}
						</Label>
						<Label title={t("quantity")}>
							{formatNumber(getFieldProps("cementAmount").value)}
						</Label>
						<Label title={t("carbonSaving")}>
							{formatNumber(
								contract?.allocatedCarbonSavingsKgCO2 ?? 0,
							)}
						</Label>
						{!values.isPreviousContact && (
							<Label title={t("projectReference")}>
								{getFieldProps("projectReference").value}
							</Label>
						)}
						{!values.isManualCertificate && (
							<Label title={t("email")}>
								{getFieldProps("email").value}
							</Label>
						)}
						{values.isManualCertificate && (
							<Label title={t("companyName")}>
								{getFieldProps("companyName").value}
							</Label>
						)}
						{values.isManualCertificate && (
							<Label title={t("address")}>
								{getFieldProps("street").value}
								<br />
								{getFieldProps("zipCode").value}{" "}
								{getFieldProps("city").value}
								<br />
								{getFieldProps("country").value}
							</Label>
						)}
						{isPartialTransfer && (
							<Alert variant="warning">
								{t("autoTransferAlert")}
							</Alert>
						)}
					</div>

					<Button
						data-cy="carbon-transfer-submit-button"
						disabled={!isValid}
						variant="primary"
						size="large"
						icon={!isLoading ? icon.mdiArrowRight : undefined}
						type={values.isManualCertificate ? "button" : "submit"}
						onClick={() => {
							if (values.isManualCertificate) {
								setValidationModal()
							}
						}}
					>
						{getButtonText()}
					</Button>
				</div>
			</DetailsCard>
			{values.isManualCertificate ? (
				<FormValidationModal />
			) : (
				<SuccessModal />
			)}
		</>
	)
}
