import { CertificateStatus } from "@shared/models"
import { Pill } from "@sustainability/fundamental"

import { certificatesStatusPillData } from "./certificatesStatusPill.config"

interface CertificateStatusPillProps {
	status: CertificateStatus
}

export const CertificateStatusPill: React.FC<CertificateStatusPillProps> = ({
	status,
}) => {
	const pillData = certificatesStatusPillData(status)

	return (
		<>
			<Pill
				size="large"
				labelIcon={pillData.icon}
				type={pillData.color}
				text={pillData.text}
			/>
		</>
	)
}
