export const materials = "/materials"
export const materialDetails = ":contractNumber/:materialNumber"
export const certificateCreation = "certificate-creation"
export const selectionOfCertificateTypeForm = "selection-of-certificate-type"
export const selectionOfMaterialForm = "selection-of-material"
export const certificateDetailsForm = "certificate-details"

export const getMaterialDetailsUrl = (contractNumber: string, id: string) =>
	`${materials}/${contractNumber}/${id}`
export const getSelectionOfCertificateTypeFormUrl = () =>
	`${materials}/${certificateCreation}/${selectionOfCertificateTypeForm}`
export const getSelectionOfMaterialFormUrl = () =>
	`${materials}/${certificateCreation}/${selectionOfMaterialForm}`
export const getCertificateDetailsFormUrl = () =>
	`${materials}/${certificateCreation}/${certificateDetailsForm}`
export const getSelectionOfCertificateTypeFormWithMaterialUrl = (
	contractNumber: string,
	id: string,
) =>
	`${getMaterialDetailsUrl(contractNumber, id)}/${certificateCreation}/${selectionOfCertificateTypeForm}`
export const getSelectionOfMaterialFormWithMaterialUrl = (
	contractNumber: string,
	id: string,
) =>
	`${getMaterialDetailsUrl(contractNumber, id)}/${certificateCreation}/${selectionOfMaterialForm}`
export const getCertificateDetailsFormWithMaterialUrl = (
	contractNumber: string,
	id: string,
) =>
	`${getMaterialDetailsUrl(contractNumber, id)}/${certificateCreation}/${certificateDetailsForm}`
