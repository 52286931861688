import { MaterialsList } from "@features/materials-list"
import { ProjectsList } from "@features/projects-list"
import { ContainerBox } from "@shared/ui"
import { PageHeader } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

export const ManageCertificatesPage = () => {
	const { t } = useTranslation("pages", { keyPrefix: "manageCertificates" })
	return (
		<>
			<PageHeader>{t("title")}</PageHeader>
			<ContainerBox className="pb-6">
				<div className="flex flex-col gap-6">
					<MaterialsList />
					<ProjectsList />
				</div>
			</ContainerBox>
		</>
	)
}
