import { useCertificateDetailsFormStore } from "@features/certificate-details-form/store"
import { CountryPill } from "@shared/ui"
import { Alert, CardHeader, Input, Select } from "@sustainability/fundamental"
import { useRef } from "react"
import { useTranslation } from "react-i18next"

interface ProvideCertificatesDetailsSectionProps {
	featureName: string
}

export const ProvideCertificatesDetailsSection: React.FC<
	ProvideCertificatesDetailsSectionProps
> = ({ featureName }) => {
	const { t } = useTranslation("features", {
		keyPrefix: `${featureName}.content.provideCertificatesDetailsSection`,
	})

	const countries = useCertificateDetailsFormStore(state => state.countries)

	const selectRef = useRef<HTMLDivElement | null>(null)

	if (!countries) {
		return <></>
	}

	return (
		<div className="flex flex-col gap-y-[12px] w-[500px] max-lg:w-full">
			<CardHeader>{t("title")}</CardHeader>
			<Alert className="mb-3" variant="warning">
				{t("alertDescription")}
			</Alert>
			<div className="flex flex-col gap-y-[12px]">
				<Input label={t("projectLabel")} name="project" />
				<Input
					label={t("companyNameLabel")}
					name="companyName"
					placeholder={t("companyNameLabel")}
				/>
				<Input
					label={t("streetLabel")}
					name="street"
					placeholder={t("streetLabel")}
				/>
				<div className="flex gap-x-[12px] max-md:flex-col max-md:gap-y-[12px]">
					<Input
						className="w-[128px] max-md:w-full"
						label={t("zipCodeLabel")}
						name="zipCode"
						placeholder={t("zipCodeLabel")}
					/>
					<Input
						className="w-full"
						label={t("cityLabel")}
						name="city"
						placeholder={t("cityLabel")}
					/>
				</div>
				<div ref={selectRef}>
					<Select
						className="w-full"
						label={t("countryLabel")}
						name="country"
						values={countries.map(x => ({
							label: <CountryPill key={x.name} country={x} />,
							value: x.name,
						}))}
						placeholder="countryPlaceholder"
						dropdownWidth={selectRef.current?.clientWidth ?? 0}
						dropdownTop={0}
					/>
				</div>
			</div>
		</div>
	)
}
