import { ProgressBar } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

import { useMaterialDetailsHeaderStore } from "../../store"

export const InvoicedQuantityProgressBar: React.FC = () => {
	const { t } = useTranslation("features", {
		keyPrefix: "materialDetailsHeader",
	})

	const invoicedQuantityBarData = useMaterialDetailsHeaderStore(
		state => state.invoicedQuantityBarData,
	)

	if (!invoicedQuantityBarData) {
		return <></>
	}

	return (
		<div
			data-cy="invoiced-quantity-progress-bar-container"
			className="xl:w-[400px] lg:w-[592px] md:w-[312px] xs:w-full"
		>
			<ProgressBar
				data-cy="invoiced-quantity-progress-bar"
				className="[&>div>div>span]:text-[16px]"
				size="large"
				currentValue={invoicedQuantityBarData.currentQuantity}
				maxValue={invoicedQuantityBarData.totalQuantity}
				label={t("invoicedQuantity")}
				valueLabel={invoicedQuantityBarData.labelValue}
			/>
		</div>
	)
}
