import {
	FundamentalContextProvider,
	ToastContainer,
	ThemeProvider,
} from "@sustainability/fundamental"
import { FundamentalHooksContextProvider } from "@sustainability/fundamental-hooks"

import CarbonBankRouter from "./app/CarbonBankRouter"
import AuthProvider from "./providers/AuthProvider"

const CarbonBankApp = () => (
	<FundamentalContextProvider>
		<FundamentalHooksContextProvider>
			<ThemeProvider>
				<AuthProvider>
					<CarbonBankRouter />
					<ToastContainer />
				</AuthProvider>
			</ThemeProvider>
		</FundamentalHooksContextProvider>
	</FundamentalContextProvider>
)

export default CarbonBankApp
