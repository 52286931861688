import { mapUnitToLocalizedUnit } from "@carbonbank/shared/lib"
import { formatIntNumber } from "@carbonbank/utils"
import { Measurement } from "@shared/models"
import { TextRegular } from "@sustainability/fundamental"

export const renderQuantity = (measurement: Measurement, dataCy: string) => (
	<>
		<TextRegular data-cy={dataCy}>
			{formatIntNumber(measurement.amount)}
		</TextRegular>{" "}
		<TextRegular>{mapUnitToLocalizedUnit(measurement.unit)}</TextRegular>
	</>
)
