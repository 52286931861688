import {
	SelectionOfCertificateFormInputs,
	useCertificateCreationFormStore,
} from "@shared/stores"
import { useFormikContext } from "formik"
import { useEffect } from "react"

import { useSelectionOfCertificateTypeFormStore } from "../../store"
import { AddProjectReferenceSection } from "./add-project-reference-section"
import { SelectProjectSection } from "./select-project-section"
import { SelectTheCertificateTypeSection } from "./select-the-certificate-type-section"

interface SelectionOfCertificateTypeContentProps {
	featureName: string
}

export const SelectionOfCertificateTypeContent: React.FC<
	SelectionOfCertificateTypeContentProps
> = ({ featureName }) => {
	const projects = useSelectionOfCertificateTypeFormStore(
		state => state.projects,
	)

	const { values } = useFormikContext<SelectionOfCertificateFormInputs>()

	const setSelectionOfCertificateTypeFormInputs =
		useCertificateCreationFormStore(
			state => state.setSelectionOfCertificateTypeFormInputs,
		)

	useEffect(() => {
		setSelectionOfCertificateTypeFormInputs(values)
	}, [values])

	return (
		<div className="flex flex-col gap-y-[48px]">
			<SelectTheCertificateTypeSection featureName={featureName} />
			{projects && projects.length > 0 ? (
				<SelectProjectSection featureName={featureName} />
			) : (
				<AddProjectReferenceSection featureName={featureName} />
			)}
		</div>
	)
}
