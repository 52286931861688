import { Unit } from "@shared/api/types"
import i18next from "@shared/i18n"

export const mapUnitToLocalizedUnit = (unit: Unit): string =>
	(
		({
			[Unit.Kg]: i18next.t("cb:units.kg"),
			[Unit.KgCo2]: i18next.t("cb:units.kgCO2"),
			[Unit.Tons]: i18next.t("cb:units.ton"),
			[Unit.M3]: i18next.t("cb:units.m3"),
		}) satisfies Record<Unit, string>
	)[unit]
