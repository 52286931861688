import { Unit } from "@carbonbank/shared/api/types"
import { mapUnitToLocalizedUnit } from "@carbonbank/shared/lib"
import { formatIntNumber } from "@carbonbank/utils"
import { Material } from "@shared/api/materials/types"
import { sortMeasurement } from "@shared/lib/table-helpers"
import { nameof } from "@shared/lib/utility-types/table"
import { getMaterialDetailsUrl } from "@shared/routes"
import {
	icon,
	Icon,
	LabelSmallRegular,
	ProgressBar,
	TextRegular,
	useSortableTable,
} from "@sustainability/fundamental"
import { TFunction } from "i18next"
import { Link } from "react-router-dom"

export const useTable = (t: TFunction<"features", string>, isMobile: boolean) =>
	useSortableTable<Material>(
		[
			{
				id: "description",
				label: t("material"),
				sortable: true,
				render: ({ description, contract }) => (
					<div className="flex flex-col max-w-[250px] md:max-w-[350px] lg:max-w-[550px] xl:max-w-[750px]">
						<TextRegular
							data-cy="material-description"
							className=" whitespace-nowrap overflow-hidden text-ellipsis"
						>
							{description}
						</TextRegular>
						<LabelSmallRegular
							data-cy="contract-number"
							className="text-foreground_faded"
						>
							{contract.number}
						</LabelSmallRegular>
					</div>
				),
				valueClassName: "lg:w-[500px] xl:w-[700px]",
				headerClassName: "lg:w-[500px] xl:w-[700px]",
			},
			{
				label: isMobile ? undefined : t("invoicedQuantity"),
				sortable: true,
				sortFunction: (left, right) =>
					sortMeasurement("invoiced")(left.contract, right.contract),
				render: ({ contract: { invoiced, total } }) => {
					return (
						<ProgressBar
							data-cy="invoiced-quantity"
							size="medium"
							currentValue={invoiced.amount}
							maxValue={total.amount}
							label={isMobile ? t("invoicedQuantity") : undefined}
							valueLabel={`${formatIntNumber(invoiced.amount)}/${formatIntNumber(total.amount)} ${mapUnitToLocalizedUnit(invoiced.unit)}`}
						/>
					)
				},
				headerClassName: "lg:text-right lg:w-[200px]",
			},
			{
				id: "availableForTransfer",
				label: t("remainingForTransfer"),
				sortable: true,
				sortFunction: (left, right) =>
					sortMeasurement("material")(
						left.availableForTransfer,
						right.availableForTransfer,
					),
				render: ({ availableForTransfer: { material, savings } }) => (
					<div className="flex flex-col">
						<TextRegular data-cy="available-for-transfer-material">
							{formatIntNumber(material.amount)}{" "}
							{mapUnitToLocalizedUnit(material.unit)}
						</TextRegular>
						<LabelSmallRegular
							data-cy="available-for-transfer-savings"
							className="text-foreground_faded"
						>
							{formatIntNumber(savings.amount)}{" "}
							{mapUnitToLocalizedUnit(Unit.KgCo2)}
						</LabelSmallRegular>
					</div>
				),
				headerClassName: "lg:text-right",
				valueClassName: "lg:text-right",
			},
			{
				label: undefined,
				valueClassName: "max-lg:hidden",
				headerClassName: "max-lg:hidden",
				sortable: false,
				render: row => (
					<Link
						to={getMaterialDetailsUrl(
							row.contract.number,
							row.itemNumber,
						)}
						className="inline-block"
					>
						<Icon path={icon.mdiChevronRight} />
					</Link>
				),
			},
		],
		[t, isMobile],
		{
			prefix: "materials-list-row",
			postfixFieldName: nameof<Material>("itemNumber"),
		},
		{ enableLocalSorting: true },
	)
