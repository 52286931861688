import {
	Button,
	DetailsCard,
	H4,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextRegular,
	TextSemiBold,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface AccountabilityModalProps {
	isModalOpen: boolean
	onClose: () => void
}

export const AccountabilityModal: React.FC<AccountabilityModalProps> = ({
	isModalOpen,
	onClose,
}) => {
	const { t } = useTranslation("features", {
		keyPrefix: "activityHistory.accountabilityModal",
	})

	return (
		<Modal
			data-cy="accountability-modal"
			className="!w-[800px]"
			open={isModalOpen}
			onClose={onClose}
		>
			<ModalTitle data-cy="accountability-modal-title" className="pt-0">
				{t("title1")}
			</ModalTitle>
			<ModalContent data-cy="accountability-modal-content">
				<div className="flex flex-col gap-6">
					<TextRegular className="block">
						{t("paragraph1")}
					</TextRegular>
					<H4 className="block text-foreground">{t("title2")}</H4>
					<TextRegular>
						{t("paragraph2")}{" "}
						<TextSemiBold className="text-foreground">
							{t("paragraph2Bold")}
						</TextSemiBold>
					</TextRegular>
					<div className="flex gap-6">
						<span className="w-[50%]">
							<TextSemiBold className="text-foreground">
								{t("paragraph3Bold")}
							</TextSemiBold>
							<TextRegular>{t("paragraph3")}</TextRegular>
						</span>

						<DetailsCard className="w-[50%]">
							<TextRegular className="text-foreground">
								{t("paragraph4")}
							</TextRegular>
						</DetailsCard>
					</div>
				</div>
			</ModalContent>
			<ModalFooter
				data-cy="accountability-modal-footer"
				className="max-md:flex-col max-md:w-full"
			>
				<Button
					onClick={onClose}
					data-cy="accountability-modal-button-close"
				>
					{t("close")}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
