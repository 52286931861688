import { LoadingPage } from "@carbonbank/components"
import {
	CertificatesList,
	InvoicesList,
	MaterialDetailsHeader,
} from "@features/index"
import { materials } from "@shared/routes"
import { useLoadingStore } from "@shared/stores"
import { DnvCarbonSavingsCard } from "@shared/ui/dnv-carbon-savings-card"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

export const MaterialDetailsPage: React.FC = () => {
	const PAGE_NAME = "materialDetails"

	const { t } = useTranslation("pages", { keyPrefix: "materialDetails" })

	const { contractNumber, materialNumber } = useParams()

	const navigate = useNavigate()

	const isLoading = useLoadingStore(state => state.isPageLoading(PAGE_NAME))

	return (
		<>
			<div>
				{isLoading && (
					<LoadingPage
						backToPageUrlOnClick={() => navigate(materials)}
						backToPageLabel={t("backLinkTitle")}
					/>
				)}
				<MaterialDetailsHeader
					pageName={PAGE_NAME}
					contractNumber={contractNumber!}
					materialNumber={materialNumber!}
					isLoading={isLoading}
				/>
				<div
					data-cy="material-details-content-box"
					className="flex flex-col gap-y-6 py-4 md:py-6 px-2 md:px-6"
				>
					<CertificatesList
						className="md:order-1 xs:order-2"
						pageName={PAGE_NAME}
						contractNumber={contractNumber!}
						materialNumber={materialNumber!}
						isLoading={isLoading}
					/>
					<DnvCarbonSavingsCard
						className="md:order-2 xs:order-1"
						isLoading={isLoading}
					/>
					<InvoicesList
						className="md:order-3 xs:order-3"
						pageName={PAGE_NAME}
						contractNumber={contractNumber!}
						materialNumber={materialNumber!}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</>
	)
}
