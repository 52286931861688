import {
	getSelectionOfMaterialFormUrl,
	getSelectionOfMaterialFormWithMaterialUrl,
} from "@shared/routes"
import {
	CertificateType,
	SelectionOfCertificateFormInputs,
	useCertificateCreationFormStore,
	useLoadingStore,
} from "@shared/stores"
import { FeatureToLoading } from "@shared/stores/loading/types"
import { FormWithSummary } from "@shared/ui"
import { CanvasCard, icon } from "@sustainability/fundamental"
import { Formik } from "formik"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { useSelectionOfCertificateValidationSchema } from "../model"
import { useSelectionOfCertificateTypeFormStore } from "../store"
import { SelectionOfCertificateTypeContent } from "./selection-of-certificate-type-content"
import { SelectionOfCertificateTypeSummary } from "./selection-of-certificate-type-summary"

interface SelectionOfCertificateTypeFormProps {
	pageName: string
	isLoading: boolean
	contractNumber?: string
	materialNumber?: string
}

export const SelectionOfCertificateTypeForm: React.FC<
	SelectionOfCertificateTypeFormProps
> = ({ pageName, isLoading, contractNumber, materialNumber }) => {
	const FEATURE_NAME = "selectionOfCertificateTypeForm"
	const featureToLoading: FeatureToLoading = {
		page: pageName,
		feature: FEATURE_NAME,
	}

	const { t } = useTranslation("features", { keyPrefix: FEATURE_NAME })

	const navigate = useNavigate()

	const [
		materialDescription,
		selectionOfCertificateTypeFormInputs,
		fetchDefaultMaterial,
		setSelectionOfCertificateTypeFormState,
	] = useCertificateCreationFormStore(state => [
		state.materialDescription,
		state.selectionOfCertificateTypeFormInputs,
		state.fetchDefaultMaterial,
		state.setSelectionOfCertificateTypeFormState,
	])
	const fetchPreviousProjects = useSelectionOfCertificateTypeFormStore(
		state => state.fetchPreviousProjects,
	)

	const [addFeatureToLoading, removeFeatureFromLoading] = useLoadingStore(
		state => [state.addFeatureToLoading, state.removeFeatureFromLoading],
	)

	const fetchInitialData = async () => {
		addFeatureToLoading(featureToLoading)

		const shouldFetchDefaultMaterial =
			!materialDescription && !!contractNumber && !!materialNumber

		if (shouldFetchDefaultMaterial) {
			await fetchDefaultMaterial(contractNumber!, materialNumber!)
		}

		await fetchPreviousProjects()

		removeFeatureFromLoading(featureToLoading)
	}

	const validationSchema = useSelectionOfCertificateValidationSchema(t)

	useEffect(() => {
		fetchInitialData()
	}, [])

	const initialState: SelectionOfCertificateFormInputs = {
		certificateType:
			selectionOfCertificateTypeFormInputs?.certificateType ??
			CertificateType.CustomerProject,
		projectId: selectionOfCertificateTypeFormInputs?.projectId,
		project: selectionOfCertificateTypeFormInputs?.project,
		newProject: selectionOfCertificateTypeFormInputs?.newProject,
	}

	const handleSubmit = (formInputs: SelectionOfCertificateFormInputs) => {
		const nextStepUrl =
			!contractNumber || !materialNumber
				? getSelectionOfMaterialFormUrl()
				: getSelectionOfMaterialFormWithMaterialUrl(
						contractNumber,
						materialNumber,
					)

		setSelectionOfCertificateTypeFormState(formInputs)
		navigate(nextStepUrl)
	}

	if (isLoading) {
		return <></>
	}

	return (
		<CanvasCard
			className="h-[calc(100vh-264px)]"
			data-cy="selection-of-Certificate-type-container"
		>
			<Formik
				initialValues={initialState}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
				validateOnMount
			>
				{({ isValid }) => (
					<FormWithSummary
						data-cy="selection-of-Certificate-type-form"
						formContent={
							<SelectionOfCertificateTypeContent
								featureName={FEATURE_NAME}
							/>
						}
						summaryTitle={t("summary.title")}
						summaryContent={
							<SelectionOfCertificateTypeSummary
								featureName={FEATURE_NAME}
							/>
						}
						isValid={isValid}
						submitTitle={t("submitTitle")}
						submitIcon={icon.mdiArrowRight}
					/>
				)}
			</Formik>
		</CanvasCard>
	)
}
