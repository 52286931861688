import { PermissionType } from "@carbonbank/api"
import { useAuthStore } from "@carbonbank/store"
import { Button } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

import { useMaterialDetailsHeaderStore } from "../../store"

interface CreateCertificateActionButtonProps {
	onClick: () => void
}

export const CreateCertificateActionButton: React.FC<
	CreateCertificateActionButtonProps
> = ({ onClick }) => {
	const { t } = useTranslation("features", {
		keyPrefix: "materialDetailsHeader",
	})

	const userPermissions = useAuthStore(state => state.userPermissions)

	const isAvailableQuantityForTransfer = useMaterialDetailsHeaderStore(
		state => state.isAvailableQuantityForTransfer,
	)

	const canCreateCertificate =
		isAvailableQuantityForTransfer &&
		userPermissions?.some(
			x => x.permissionType === PermissionType.ManageCertiticates,
		)

	return (
		<div
			data-cy="create-certificate-action-button-size-container"
			className="md:w-[190px] xs:w-full"
		>
			<Button
				data-cy="create-certificate-action-button"
				disabled={!canCreateCertificate}
				size="large"
				onClick={onClick}
				className="w-full"
			>
				{t("btnCreateCertificate")}
			</Button>
		</div>
	)
}
