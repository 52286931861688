import { materialsApi } from "@shared/api/materials"
import { Material } from "@shared/api/materials/types"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	materials: Material[] | undefined
	canLoadMoreData: boolean
}

type Actions = {
	fetchMaterials: (showTransferred: boolean) => Promise<void>
}

export const useMaterialsStore = create<State & Actions>()(
	immer(set => ({
		materials: undefined,
		canLoadMoreData: false,
		fetchMaterials: async (showTransferred: boolean) => {
			const response = await materialsApi.get(showTransferred)

			set(state => {
				state.materials = response.items
				state.canLoadMoreData = !showTransferred
			})
		},
		resetStore: () => {
			set(state => {
				state.materials = undefined
				state.canLoadMoreData = false
			})
		},
	})),
)
