import {
	formatDate,
	formatIntNumber,
	getTransactionHashScanLink,
} from "@carbonbank/utils"
import { InvoiceForMaterialItem } from "@shared/api/materials/types"
import i18next from "@shared/i18n"
import { mapUnitToLocalizedUnit } from "@shared/lib"

import { FormattedInvoicesForMaterialItem } from "../../store/types"

export const formatInvoicesForMaterialItem = (
	invoices: InvoiceForMaterialItem[],
): FormattedInvoicesForMaterialItem[] =>
	invoices.map(invoice => ({
		createdOn: formatDate(invoice.createdOn),
		invoiceNumber: invoice.invoiceNumber,
		deliveredQuantity: `${formatIntNumber(invoice.invoicedQuantity.amount)} ${mapUnitToLocalizedUnit(invoice.invoicedQuantity.unit)}`,
		bundledCarbonSavings: `${formatIntNumber(invoice.carbonSavingsQuantity.amount)} ${mapUnitToLocalizedUnit(invoice.carbonSavingsQuantity.unit)} ${i18next.t("cb:units.co2")}`,
		transactionId: invoice.transactionId,
		transactionLink: getTransactionHashScanLink(invoice.transactionId),
	}))
