import { materialsApi } from "@shared/api/materials"
import { spawnErrorToast } from "@sustainability/fundamental"
import i18next from "i18next"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { formatCertificatesForMaterialItem } from "../util"
import { FormattedCertificatesForMaterialItem } from "./types"

type State = {
	certificates: FormattedCertificatesForMaterialItem[]
}

type Actions = {
	fetchCertificatesForMaterialItem: (
		contractNumber: string,
		materialNumber: string,
	) => Promise<void>
	resetStore: () => void
}

export const useCertificatesForMaterialStore = create<State & Actions>()(
	immer(set => ({
		certificates: [],

		fetchCertificatesForMaterialItem: async (
			contractNumber: string,
			materialNumber: string,
		) => {
			try {
				const response =
					await materialsApi.getCertificatesForMaterialItem(
						contractNumber,
						materialNumber,
					)

				const formattedCertificates = formatCertificatesForMaterialItem(
					response.certificates,
				)

				set(state => {
					state.certificates = formattedCertificates
				})
			} catch {
				spawnErrorToast(
					i18next.t("features:certificatesList.errorMessage"),
				)
			}
		},
		resetStore: () => {
			set(state => {
				state.certificates = []
			})
		},
	})),
)
