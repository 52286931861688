import { authenticationClient } from "../client"
import { generateGetUrl } from "../utils"
import { User, UserPermission, UserRole } from "./types"

export const authenticationApi = {
	getUserById: (id: string): Promise<User> => {
		return authenticationClient
			.get(`/users/${id}`)
			.then(response => response.data)
	},
	updateById: (id: string, patch: Partial<User>): Promise<User> =>
		authenticationClient
			.put(`/users/${id}`, patch)
			.then(response => response.data),
	getPermissionsByUserId: (userId: string): Promise<UserPermission[]> =>
		authenticationClient
			.get(generateGetUrl("/permissions", { userId: userId }))
			.then(response => response.data),
	getRolesByUserId: (userId: string): Promise<UserRole[]> =>
		authenticationClient
			.get(generateGetUrl("/roles", { userId: userId }))
			.then(response => response.data),
}
