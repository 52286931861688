import { LoadingPage } from "@carbonbank/components"
import {
	CertificatesDetailsForm,
	SelectionOfCertificateTypeForm,
	SelectionOfMaterialForm,
} from "@features/index"
import { getStepperItemState } from "@shared/lib"
import {
	certificateCreation,
	certificateDetailsForm,
	getMaterialDetailsUrl,
	getSelectionOfCertificateTypeFormUrl,
	getSelectionOfCertificateTypeFormWithMaterialUrl,
	materials,
	selectionOfCertificateTypeForm,
	selectionOfMaterialForm,
} from "@shared/routes"
import {
	useCertificateCreationFormStore,
	useLoadingStore,
} from "@shared/stores"
import {
	icon,
	PageHeader,
	Stepper,
	StepperItemConfig,
} from "@sustainability/fundamental"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"

export const CertificateCreationPage: React.FC = () => {
	const PAGE_NAME = "certificateCreation"

	const { t } = useTranslation("pages", { keyPrefix: PAGE_NAME })

	const location = useLocation()
	const { contractNumber, materialNumber } = useParams()
	const navigate = useNavigate()

	const [currentStep, setCurrentStep] = useState(0)

	const isLoading = useLoadingStore(state => state.isPageLoading(PAGE_NAME))

	const [
		materialDescription,
		selectionOfCertificateTypeFormState,
		selectionOfMaterialFormState,
		certificateDetailsFormState,
		resetCertificateData,
	] = useCertificateCreationFormStore(state => [
		state.materialDescription,
		state.selectionOfCertificateTypeFormState,
		state.selectionOfMaterialFormState,
		state.certificateDetailsFormState,
		state.resetCertificateData,
	])

	const isCertificateCreationWithMaterial = useMemo(
		() => !!materialNumber && !!contractNumber,
		[materialNumber, contractNumber],
	)
	const backToPageUrl = isCertificateCreationWithMaterial
		? getMaterialDetailsUrl(contractNumber!, materialNumber!)
		: materials
	const backToPageLabel = isCertificateCreationWithMaterial
		? materialDescription
			? t("backLinkToMaterialDetailsWithDescriptionTitle", {
					materialDescription,
				})
			: t("backLinkToMaterialDetailsTitle")
		: t("backLinkToManageCertificatesTitle")

	const stepIndexes = useMemo(
		() => ({
			firstStep: 0,
			secondStep: 1,
			thirdStep: 2,
		}),
		[],
	)

	const steps: StepperItemConfig[] = useMemo(
		() => [
			{
				title: t("steps.firstStep.title"),
				icon: icon.mdiAccountCircleOutline,
				label: t("steps.firstStep.label"),
				state: getStepperItemState(
					currentStep,
					stepIndexes.firstStep,
					!!selectionOfCertificateTypeFormState,
				),
				content: (
					<SelectionOfCertificateTypeForm
						pageName={PAGE_NAME}
						isLoading={isLoading}
						contractNumber={contractNumber}
						materialNumber={materialNumber}
					/>
				),
			},
			{
				title: t("steps.secondStep.title"),
				icon: icon.mdiAccountCircleOutline,
				label: t("steps.secondStep.label"),
				state: getStepperItemState(
					currentStep,
					stepIndexes.secondStep,
					!!selectionOfMaterialFormState,
				),
				content: (
					<SelectionOfMaterialForm
						pageName={PAGE_NAME}
						isLoading={isLoading}
						contractNumber={contractNumber}
						materialNumber={materialNumber}
					/>
				),
			},
			{
				title: t("steps.thirdStep.title"),
				icon: icon.mdiAccountCircleOutline,
				label: t("steps.thirdStep.label"),
				state: getStepperItemState(
					currentStep,
					stepIndexes.thirdStep,
					!!certificateDetailsFormState,
				),
				content: (
					<CertificatesDetailsForm
						pageName={PAGE_NAME}
						isLoading={isLoading}
						contractNumber={contractNumber}
						materialNumber={materialNumber}
					/>
				),
			},
		],
		[
			currentStep,
			isLoading,
			contractNumber,
			materialNumber,
			selectionOfCertificateTypeFormState,
			selectionOfMaterialFormState,
			certificateDetailsFormState,
		],
	)

	useEffect(() => {
		return () => {
			const shouldResetCertificateData =
				!location.pathname.includes(certificateCreation)

			if (shouldResetCertificateData) {
				resetCertificateData()
			}
		}
	}, [])

	useEffect(() => {
		const firstStepNumber = location.pathname.includes(
			selectionOfCertificateTypeForm,
		)
			? stepIndexes.firstStep
			: undefined
		const secondStepNumber = location.pathname.includes(
			selectionOfMaterialForm,
		)
			? stepIndexes.secondStep
			: undefined
		const thirdStepNumber = location.pathname.includes(
			certificateDetailsForm,
		)
			? stepIndexes.thirdStep
			: undefined

		setCurrentStep(
			firstStepNumber ??
				secondStepNumber ??
				thirdStepNumber ??
				stepIndexes.firstStep,
		)
	}, [location.pathname])

	useEffect(() => {
		const redirectToFirstStep = isCertificateCreationWithMaterial
			? getSelectionOfCertificateTypeFormWithMaterialUrl(
					contractNumber!,
					materialNumber!,
				)
			: getSelectionOfCertificateTypeFormUrl()
		const shouldRedirectToFirstStep =
			(currentStep === stepIndexes.secondStep &&
				!selectionOfCertificateTypeFormState) ||
			(currentStep === stepIndexes.thirdStep &&
				(!selectionOfCertificateTypeFormState ||
					!selectionOfMaterialFormState))

		if (shouldRedirectToFirstStep) {
			navigate(redirectToFirstStep)
		}
	}, [currentStep])

	const handleBack = () => {
		resetCertificateData()
		navigate(backToPageUrl)
	}

	return (
		<>
			{isLoading && (
				<LoadingPage
					backToPageUrlOnClick={handleBack}
					backToPageLabel={backToPageLabel}
				/>
			)}
			{!isLoading && (
				<PageHeader
					backToPageUrlOnClick={handleBack}
					backToPageLabel={backToPageLabel}
				>
					{t("title")}
				</PageHeader>
			)}
			<Stepper
				items={steps}
				activeItem={currentStep}
				headerClassname={isLoading ? "hidden" : ""}
				contentClassname={isLoading ? "hidden" : ""}
			/>
		</>
	)
}
