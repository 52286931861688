import { useSelectionOfCertificateTypeFormStore } from "@features/selection-of-certificate-type-form/store"
import {
	CardHeader,
	Input,
	Radio,
	RadioGroup,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

interface SelectProjectSectionProps {
	featureName: string
}

export const SelectProjectSection: React.FC<SelectProjectSectionProps> = ({
	featureName,
}) => {
	const { t } = useTranslation("features", {
		keyPrefix: `${featureName}.content.selectProjectSection`,
	})

	const projects = useSelectionOfCertificateTypeFormStore(
		state => state.projects,
	)

	return (
		<div>
			<CardHeader>{t("title")}</CardHeader>
			<RadioGroup className="xl:w-[62.75%] lg:w-full [&>tr]:border-b-solid [&>tr]:border-b-[1px]">
				<>
					{projects?.map((project, index) => (
						<Radio
							key={project.name}
							label={project.name}
							name="project"
							value={project.name}
							checked={index === 0}
						/>
					))}
				</>
				<Radio className="items-center" name="project" value="">
					<Input
						className="w-full p-x-[16px] [&>div>div>input]:min-h-[48px]"
						name="newProject"
						placeholder={t("createNewProjectPlaceholder")}
					/>
				</Radio>
			</RadioGroup>
		</div>
	)
}
