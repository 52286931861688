import { useTransactionStore } from "@carbonbank/store"
import { formatIntNumber } from "@carbonbank/utils"
import { Kpi } from "@sustainability/fundamental"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"

export const TransactionStats: React.FC = () => {
	const { t } = useTranslation("transactions", {
		keyPrefix: "transactions.components.transactionsStats",
	})
	const [totalCarbonCaptured, fetchTotalCarbonCaptured] = useTransactionStore(
		state => [state.totalCarbonCaptured, state.fetchTotalCarbonCaptured],
	)

	useEffect(() => {
		fetchTotalCarbonCaptured()
	}, [fetchTotalCarbonCaptured])

	return (
		<Kpi
			data-cy="transaction-stats"
			size="xlarge"
			label={t("totalCarbonCaptured")}
			value={formatIntNumber(totalCarbonCaptured ?? 0)}
			valueClassName="text-primary"
			unit="kg CO2"
		/>
	)
}
