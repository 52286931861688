import { transferApi, TransferCertificate } from "@carbonbank/api"
import { CertificatePdfDocument } from "@carbonbank/pdf/certificates/transfer/CertificatePdfDocument"
import { downloadFile } from "@carbonbank/utils/blobTransformation"
import ReactPDF from "@react-pdf/renderer"
import { projectsApi } from "@shared/api"
import i18n from "@shared/i18n"
import { ProjectDetailCertificate } from "@shared/models"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

export type State = {
	certificates: ProjectDetailCertificate[]
}

type Actions = {
	fetchCertificatesForProjectDetail: (projectId: string) => Promise<void>
	downloadAllCertificates: () => Promise<void>
	resetStore: () => void
}

export const useProjectDetailCertificates = create<State & Actions>()(
	immer((set, get) => ({
		certificates: [],

		fetchCertificatesForProjectDetail: async (projectId: string) => {
			try {
				const response =
					await projectsApi.getCertificatesForProject(projectId)

				const certificates: ProjectDetailCertificate[] = response.map(
					c => ({
						materialDescription: c.materialDescription,
						createdOn: c.createdOn,
						quantity: c.quantity,
						totalSavings: c.totalSavings,
						transactionId: c.transactionId,
					}),
				)
				set(state => {
					state.certificates = certificates
				})
			} catch {
				spawnErrorToast(
					i18n.t(
						"shared:projectCertificates.errors.certificatesFetchFailed",
					),
				)
			}
		},
		downloadAllCertificates: async () => {
			if (!get().certificates) {
				return
			}

			const downloadPromises = get().certificates.map(
				({ transactionId }) =>
					transferApi.getTransferCertificate(transactionId),
			)

			const certificateData = await Promise.allSettled(downloadPromises)
			if (certificateData.some(({ status }) => status === "rejected")) {
				spawnErrorToast(
					i18n.t(
						"shared:projectCertificates.errors.certificatesDownloadFailed",
					),
				)
			}

			certificateData
				.filter(({ status }) => status === "fulfilled")
				.forEach(async data => {
					const { value } =
						data as PromiseFulfilledResult<TransferCertificate>
					const pdfBlob = await ReactPDF.pdf(
						<CertificatePdfDocument data={value} />,
					).toBlob()

					downloadFile(
						"certificate.pdf",
						[pdfBlob],
						"application/pdf",
					)
				})
		},
		resetStore: () => {
			set(state => {
				state.certificates = []
			})
		},
	})),
)
