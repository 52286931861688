import { FormWithSummary } from "@carbonbank/shared/ui"
import {
	getCertificateDetailsFormUrl,
	getCertificateDetailsFormWithMaterialUrl,
	getSelectionOfCertificateTypeFormUrl,
	getSelectionOfCertificateTypeFormWithMaterialUrl,
} from "@shared/routes"
import {
	SelectionOfMaterialFormInputs,
	useCertificateCreationFormStore,
	useLoadingStore,
} from "@shared/stores"
import { FeatureToLoading } from "@shared/stores/loading/types"
import { CanvasCard, icon } from "@sustainability/fundamental"
import { Formik } from "formik"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { useValidationSchema } from "../models"
import { useSelectionOfMaterialFormStore } from "../store"
import { SelectionOfMaterialContent } from "./selection-of-material-content"
import { SelectionOfMaterialSummary } from "./selection-of-material-summary"

export type SelectionOfMaterialFormProps = {
	pageName: string
	isLoading: boolean
	contractNumber?: string
	materialNumber?: string
}

export const SelectionOfMaterialForm: React.FC<
	SelectionOfMaterialFormProps
> = ({
	pageName,
	isLoading,
	contractNumber,
	materialNumber: materialNumberProp,
}) => {
	const FEATURE_NAME = "selectionOfMaterialForm"
	const featureToLoading: FeatureToLoading = {
		page: pageName,
		feature: FEATURE_NAME,
	}

	const { t } = useTranslation("features", { keyPrefix: FEATURE_NAME })

	const navigate = useNavigate()

	const [addFeatureToLoading, removeFeatureFromLoading] = useLoadingStore(
		state => [state.addFeatureToLoading, state.removeFeatureFromLoading],
	)

	const validationSchema = useValidationSchema(t)

	const [materials, fetchNotTransferedMaterials] =
		useSelectionOfMaterialFormStore(state => [
			state.materials,
			state.fetchNotTransferedMaterials,
		])
	const [
		materialNumber,
		selectionOfMaterialFormInputs,
		setSelectionOfMaterialFormState,
	] = useCertificateCreationFormStore(state => [
		state.materialNumber,
		state.selectionOfMaterialFormInputs,
		state.setSelectionOfMaterialFormState,
	])

	const fetchNotTransferedMaterialsData = async () => {
		addFeatureToLoading(featureToLoading)

		await fetchNotTransferedMaterials()

		removeFeatureFromLoading(featureToLoading)
	}

	useEffect(() => {
		fetchNotTransferedMaterialsData()
	}, [])

	const shouldNotRender = isLoading || !materials || materials.length === 0

	if (shouldNotRender) {
		return <></>
	}

	const defaultMaterial = materialNumber
		? materials.find(m => m.itemNumber === materialNumber)
		: materials[0]

	const initialValues: SelectionOfMaterialFormInputs = {
		material:
			selectionOfMaterialFormInputs?.material ??
			defaultMaterial!.itemNumber,
		materialDescription:
			selectionOfMaterialFormInputs?.materialDescription ??
			defaultMaterial!.description,
		quantity:
			selectionOfMaterialFormInputs?.quantity ??
			defaultMaterial!.availableForTransfer.material.amount,
		totalQuantity:
			selectionOfMaterialFormInputs?.totalQuantity ??
			defaultMaterial!.availableForTransfer.material.amount,
		savings:
			selectionOfMaterialFormInputs?.savings ??
			defaultMaterial!.availableForTransfer.savings.amount,
		unit:
			selectionOfMaterialFormInputs?.unit ??
			defaultMaterial!.availableForTransfer.material.unit,
	}

	const handleSubmit = async (values: SelectionOfMaterialFormInputs) => {
		const nextStepUrl =
			!contractNumber || !materialNumberProp
				? getCertificateDetailsFormUrl()
				: getCertificateDetailsFormWithMaterialUrl(
						contractNumber,
						materialNumberProp,
					)

		setSelectionOfMaterialFormState(values)
		navigate(nextStepUrl)
	}

	const handleBack = () => {
		const previousStepUrl =
			!contractNumber || !materialNumberProp
				? getSelectionOfCertificateTypeFormUrl()
				: getSelectionOfCertificateTypeFormWithMaterialUrl(
						contractNumber,
						materialNumberProp,
					)

		navigate(previousStepUrl)
	}

	return (
		<CanvasCard className="relative h-[calc(100vh-266px)]">
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validationSchema={validationSchema}
				validateOnMount
			>
				{({ isValid }) => (
					<FormWithSummary
						data-cy="material-selection-form"
						formContent={
							<SelectionOfMaterialContent
								featureName={FEATURE_NAME}
							/>
						}
						summaryTitle={t("summary.title")}
						summaryContent={
							<SelectionOfMaterialSummary
								featureName={FEATURE_NAME}
							/>
						}
						submitTitle={t("submitTitle")}
						submitIcon={icon.mdiArrowRight}
						backTitle={t("backTitle")}
						onBack={handleBack}
						isValid={isValid}
					/>
				)}
			</Formik>
		</CanvasCard>
	)
}
