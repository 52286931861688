import { clx } from "@sustainability/fundamental"

interface RoundedBoxProps {
	children?: React.ReactNode
	"data-cy"?: string
	className?: string
}

export const RoundedBox: React.FC<RoundedBoxProps> = ({
	children,
	"data-cy": dataCy,
	className,
}) => {
	return (
		<div
			data-cy={dataCy}
			className={clx(
				"rounded-lg bg-container_background text-foreground",
				className,
			)}
		>
			{children}
		</div>
	)
}
