import { FormattedCountry } from "@shared/models"
import { TextRegular } from "@sustainability/fundamental"

interface CountryPillProps {
	country: FormattedCountry
}

export const CountryPill: React.FC<CountryPillProps> = ({ country }) => (
	<div className="flex items-center w-full">
		<img
			src={country.icon}
			alt={country.name}
			className="w-[16px] h-[12px]"
		/>
		<TextRegular className="ml-2 text-xs font-semibold">
			{country.name}
		</TextRegular>
	</div>
)
