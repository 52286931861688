import { projectsApi } from "@shared/api"
import i18n from "@shared/i18n"
import { spawnErrorToast } from "@sustainability/fundamental"
import { AxiosError } from "axios"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { ProjectDetailHeaderData } from "../model/types"

type State = {
	projectDetail: ProjectDetailHeaderData | undefined
}

type Actions = {
	fetchProjectDetail: (projectId: string) => Promise<void>
	resetStore: () => void
}

export const useProjectDetailHeaderStore = create<State & Actions>()(
	immer(set => ({
		projectDetail: undefined,
		fetchProjectDetail: async (projectId: string) => {
			try {
				const response = await projectsApi.getById(projectId)
				const projectDetail: ProjectDetailHeaderData = {
					id: response.id,
					reference: response.reference,
					cementQuantity: response.cementQuantity,
					concreteQuantity: response.concreteQuantity,
					carbonSavings: response.savings,
					status: response.status,
				}

				set(() => ({
					projectDetail,
				}))
			} catch (error) {
				const axiosError = error as AxiosError
				const isNotFoundError = axiosError?.response?.status === 404

				if (isNotFoundError) {
					spawnErrorToast(
						i18n.t("features:projectDetailHeader.errors.notFound"),
					)

					window.location.href = "/not-found"
				}
			}
		},
		resetStore: () => {
			set(() => ({
				projectDetail: undefined,
			}))
		},
	})),
)
