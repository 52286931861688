import { formatIntNumber } from "@carbonbank/utils"
import {
	ContractForMaterial,
	Material,
	MaterialForTransfer,
} from "@shared/api/materials/types"
import i18next from "@shared/i18n"
import { mapUnitToLocalizedUnit } from "@shared/lib"

import {
	FormattedInvoicedQuantityBarData,
	FormattedRemainForCertificateCreationKpiData,
	MaterialDetailsHeaderData,
} from "../../store/types"

export const mapMaterialToMaterialDetailsHeaderData = (
	material: Material,
): MaterialDetailsHeaderData => ({
	materialDescription: material.description,
	contractNumber: material.contract.number,
})

export const formatContractToInvoicedQuantityBarData = (
	contract: ContractForMaterial,
): FormattedInvoicedQuantityBarData => ({
	currentQuantity: contract.invoiced.amount,
	totalQuantity: contract.total.amount,
	labelValue: `${formatIntNumber(contract.invoiced.amount)} / ${formatIntNumber(contract.total.amount)} ${mapUnitToLocalizedUnit(contract.invoiced.unit)}`,
})

export const formatMaterialForTransferToRemainForCertificateCreationKpiData = (
	materialForTransfer: MaterialForTransfer,
): FormattedRemainForCertificateCreationKpiData => ({
	value: `${formatIntNumber(materialForTransfer.material.amount)} ${mapUnitToLocalizedUnit(materialForTransfer.material.unit)} / ${materialForTransfer.savings.amount} ${mapUnitToLocalizedUnit(materialForTransfer.savings.unit)} ${i18next.t("cb:units.co2")}`,
})
