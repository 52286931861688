import {
	formatIntNumber,
	formatTimestamp,
	getTransactionHashScanLink,
} from "@carbonbank/utils"
import { CertificateForMaterialItem } from "@shared/api/materials/types"
import i18next from "@shared/i18n"
import { mapUnitToLocalizedUnit } from "@shared/lib"

import { FormattedCertificatesForMaterialItem } from "../../store/types"

export const formatCertificatesForMaterialItem = (
	certificates: CertificateForMaterialItem[],
): FormattedCertificatesForMaterialItem[] =>
	certificates.map(certificate => ({
		projectReference: certificate.projectReference,
		date: formatTimestamp(certificate.date),
		quantity: `${formatIntNumber(certificate.quantity.amount)} ${mapUnitToLocalizedUnit(certificate.quantity.unit)}`,
		carbonSavingsQuantity: `${formatIntNumber(certificate.totalSavings.amount)} ${mapUnitToLocalizedUnit(certificate.totalSavings.unit)} ${i18next.t("cb:units.co2")}`,
		transactionId: certificate.transactionId,
		transactionLink:
			certificate.transactionId &&
			getTransactionHashScanLink(certificate.transactionId),
		status: certificate.status,
	}))
