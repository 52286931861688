import {
	Button,
	ExternalLink,
	icon,
	TextRegular,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

import { getProofLink } from "../../../utils"

export type TransactionRowProps = {
	material?: string
	proofId: string
}

export const TransactionRow: React.FC<TransactionRowProps> = ({
	material,
	proofId,
}) => {
	const { t } = useTranslation("features", {
		keyPrefix: "activityHistory.proofIdsModal",
	})

	return (
		<div
			data-cy={`proof-ids-row-${proofId}`}
			className="flex gap-6 items-center border-b-solid border-b-border border-b-1px h-[56px] relative"
		>
			{material && (
				<TextRegular
					data-cy={`proof-ids-row-${proofId}-material`}
					className={`${!material && "absolute left-4"} w-[30%] p-4 whitespace-nowrap overflow-hidden text-ellipsis`}
				>
					{material}
				</TextRegular>
			)}
			<TextRegular
				data-cy={`proof-ids-row-${proofId}-proofId`}
				className={`${!material && "absolute left-4"} p-4 w-[30%] whitespace-nowrap overflow-hidden text-ellipsis`}
			>
				{proofId}
			</TextRegular>
			<ExternalLink
				key={proofId}
				className={`${!material && "absolute right-4"} p-4`}
				href={getProofLink(
					material ? "transaction" : "measurement",
					proofId,
				)}
			>
				<Button icon={icon.mdiOpenInNew} variant="tertiary">
					{t("open")}
				</Button>
			</ExternalLink>
		</div>
	)
}
