export type PaginationRequest = {
	page: number
	pageSize: number
}

export type PaginationResponse = {
	page: number
	pageSize: number
	totalCount: number
}

export enum Unit {
	KgCo2 = "kgCo2",
	Kg = "kg",
	Tons = "tons",
	M3 = "m3",
}
