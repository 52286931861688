import { Unit } from "@shared/api/types"
import { mapUnitToLocalizedUnit } from "@shared/lib"
import {
	SelectionOfMaterialFormInputs,
	useCertificateCreationFormStore,
} from "@shared/stores"
import {
	CardHeader,
	Input,
	LabelRegular,
	Select,
	TextMedium,
} from "@sustainability/fundamental"
import { useFormikContext } from "formik"
import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"

import { useSelectionOfMaterialFormStore } from "../../store"

interface SelectionOfMaterialContentProps {
	featureName: string
}

export const SelectionOfMaterialContent: React.FC<
	SelectionOfMaterialContentProps
> = ({ featureName }) => {
	const { t } = useTranslation("features", {
		keyPrefix: `${featureName}.content`,
	})

	const materials = useSelectionOfMaterialFormStore(state => state.materials)

	const [selectionOfMaterialFormInputs, setSelectionOfMaterialFormInputs] =
		useCertificateCreationFormStore(state => [
			state.selectionOfMaterialFormInputs,
			state.setSelectionOfMaterialFormInputs,
		])

	const { values, setFieldValue } =
		useFormikContext<SelectionOfMaterialFormInputs>()

	const selectRef = useRef<HTMLDivElement | null>(null)

	if (!materials || materials.length === 0) {
		return <></>
	}

	const selectedMaterial = materials.find(
		x => x.itemNumber === values.material,
	)!

	useEffect(() => {
		if (selectionOfMaterialFormInputs?.material !== values.material) {
			setFieldValue(
				"quantity",
				selectedMaterial.availableForTransfer.material.amount,
			)
		}

		setFieldValue(
			"unit",
			selectedMaterial.availableForTransfer.material.unit,
		)

		const savings = selectedMaterial.availableForTransfer.savings.amount / selectedMaterial.availableForTransfer.material.amount * values.quantity

		setFieldValue(
			"savings",
			savings,
		)
		setFieldValue("materialDescription", selectedMaterial.description)
		setFieldValue("customerId", selectedMaterial.customerId)
	}, [values.material])

	useEffect(() => {
		const savings = selectedMaterial.availableForTransfer.savings.amount / selectedMaterial.availableForTransfer.material.amount * values.quantity

		setFieldValue(
			"savings",
			savings,
		)
		setSelectionOfMaterialFormInputs(values)
	}, [values])

	return (
		<div className="flex flex-col gap-y-[12px] relative">
			<CardHeader className="!text-wrap">{t("title")}</CardHeader>
			<div className="flex flex-col gap-y-[12px]">
				<div ref={selectRef}>
					<Select
						className="w-full [&>div>div:last-child]:h-full [&>div>div:last-child]:overflow-y-scroll"
						label={t("materialListLabel")}
						name="material"
						values={materials.map(x => ({
							label: (
								<>
									<TextMedium>{x.description}</TextMedium>
									<LabelRegular className="text-foreground_faded">
										({x.itemNumber})
									</LabelRegular>
								</>
							),
							value: x.itemNumber,
						}))}
						dropdownWidth={selectRef.current?.clientWidth ?? 0}
						dropdownTop={0}
					/>
				</div>
				<div className="flex items-center gap-[24px] max-md:flex-col max-md:items-start">
					<Input
						className="lg:w-[200px] max-md:w-full"
						label={t("materialQuantityLabel", {
							unit: mapUnitToLocalizedUnit(
								selectedMaterial.availableForTransfer.material
									.unit,
							),
						})}
						name="quantity"
						defaultValue={
							selectedMaterial.availableForTransfer.material
								.amount
						}
					/>
					<div className="flex flex-col">
						<LabelRegular className="text-foreground_faded">
							{t("availableQuantityLabel", {
								unit: mapUnitToLocalizedUnit(
									selectedMaterial.availableForTransfer
										.material.unit,
								),
							})}
						</LabelRegular>
						<TextMedium>
							{
								selectedMaterial.availableForTransfer.material
									.amount
							}
						</TextMedium>
					</div>
					<div className="flex flex-col">
						<LabelRegular className="text-foreground_faded">
							{t("carbonSavingsLabel", {
								unit: mapUnitToLocalizedUnit(Unit.KgCo2),
							})}
						</LabelRegular>
						<TextMedium>
							{
								selectedMaterial.availableForTransfer.savings
									.amount
							}
						</TextMedium>
					</div>
				</div>
			</div>
		</div>
	)
}
