import { Country } from "@shared/api"
import { FormattedCountry } from "@shared/models"
import { icon } from "@sustainability/fundamental"
import i18next from "i18next"

export const mapCountryNameToCountryIcon = (name: string): string =>
	(
		({
			["Norway"]: icon.mdiDivingScubaFlag,
			["Sweden"]: icon.mdiDivingScubaFlag,
			["Bulgaria"]: icon.mdiDivingScubaFlag,
			["Greece"]: icon.mdiDivingScubaFlag,
			["Romania"]: icon.mdiDivingScubaFlag,
			["France"]: icon.mdiDivingScubaFlag,
			["Netherlands"]: icon.mdiDivingScubaFlag,
			["Belgium"]: icon.mdiDivingScubaFlag,
			["Poland"]: icon.mdiDivingScubaFlag,
			["Czech Republic"]: icon.mdiDivingScubaFlag,
			["Germany"]: icon.mdiDivingScubaFlag,
			["Italy"]: icon.mdiDivingScubaFlag,
			["Denmark"]: icon.mdiDivingScubaFlag,
			["Estonia"]: icon.mdiDivingScubaFlag,
			["Latvia"]: icon.mdiDivingScubaFlag,
			["Lithuania"]: icon.mdiDivingScubaFlag,
			["Iceland"]: icon.mdiDivingScubaFlag,
			["United Kingdom"]: icon.mdiDivingScubaFlag,
			["Spain"]: icon.mdiDivingScubaFlag,
		}) satisfies Record<string, string>
	)[name] || icon.mdiFlag

export const formatCountries = (countries: Country[]): FormattedCountry[] =>
	countries.map(country => ({
		icon: mapCountryNameToCountryIcon(country.countryName),
		name: i18next.t(`shared:country.${country.countryName}`),
	}))
