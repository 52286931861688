import {
	AssuredYearlyBalanceResponse,
	CarbonSourceType,
	UpdateBalanceInput,
	TransactionOverviewData,
	YearlySavingsChartData,
	YearlySavingsReport,
	YearlyTransactionItem,
	TransactionOverviewSearchParams,
	ReportDataSearchParams,
	AssureYearlyBalancePayload,
	yearlySavingsApi,
} from "@carbonbank/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	savingsChartData?: YearlySavingsChartData[]
	savingsStats?: TransactionOverviewData
	transactionsData?: YearlyTransactionItem[]
	reportData?: YearlySavingsReport
}
type Actions = {
	fetchTransactionsData: (
		year: number,
		plantNumber: string,
		carbonSourceType: CarbonSourceType,
	) => Promise<void>
	updateBalance: (data: UpdateBalanceInput) => Promise<void>
	fetchReportData: (year: string, plantNumber: string) => Promise<void>
	assureYearlyBalance: (
		year: string,
		location: string,
		serialNumber: string,
	) => Promise<AssuredYearlyBalanceResponse>
}

export const useYearlySavingsStore = create<State & Actions>()(
	immer(set => ({
		savingsChartData: undefined,
		savingsStats: undefined,
		transactionsData: undefined,
		fetchTransactionsData: async (
			year: number,
			plantNumber: string,
			carbonSourceType: CarbonSourceType,
		) => {
			try {
				if (!year || !plantNumber) {
					return
				}

				const params: TransactionOverviewSearchParams = {
					year,
					carbonSourceType,
					plantNumber: plantNumber,
				}
				const response =
					await yearlySavingsApi.getTransactionsData(params)

				set(state => {
					state.transactionsData = response.transactions
					state.savingsChartData =
						response.transactionOverviewChartData
					state.savingsStats = { ...response }
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch bank balance stats data")
			}
		},
		updateBalance: async (data: UpdateBalanceInput) => {
			try {
				await yearlySavingsApi.updateBalance(data)
			} catch (e) {
				spawnErrorToast("Failed to adjust balance:")
			}
		},
		fetchReportData: async (year: string, plantNumber: string) => {
			try {
				const params: ReportDataSearchParams = {
					year: parseInt(year),
					plantNumber: plantNumber,
				}
				const response = await yearlySavingsApi.getReportData(params)

				set(state => {
					state.reportData = response
				})
			} catch (error) {
				spawnErrorToast("Failed to fetch bank balance stats data")
			}
		},
		assureYearlyBalance: async (
			year: string,
			location: string,
			serialNumber: string,
		) => {
			//TODO: Add correct error handling (use axios interseptor)
			try {
				const payload: AssureYearlyBalancePayload = {
					year: parseInt(year),
					plantName: location,
					serialNumber,
				}
				return await yearlySavingsApi.assureYearlyBalance(payload)
			} catch (error) {
				spawnErrorToast("Failed to assure yearly balance")
			}
			return {
				transactionId: "",
				releasedBuffer: 0,
			}
		},
	})),
)
