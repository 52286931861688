import { configurationsApi } from "@shared/api"
import { formatCountries } from "@shared/lib"
import { FormattedCountry } from "@shared/models"
import { spawnErrorToast } from "@sustainability/fundamental"
import i18next from "i18next"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	countries?: FormattedCountry[]
}

type Actions = {
	fetchCountries: () => Promise<void>
}

export const useCertificateDetailsFormStore = create<State & Actions>()(
	immer(set => ({
		countries: undefined,

		fetchCountries: async () => {
			try {
				const countries = await configurationsApi.getCounties()

				const formattedCountries = formatCountries(countries)

				set(state => {
					state.countries = formattedCountries
				})
			} catch {
				spawnErrorToast(
					i18next.t(
						"features:certificatesDetailsForm.errorMessages.fetchCountriesError",
					),
				)
			}
		},
	})),
)
