import { Material, materialsApi } from "@shared/api"
import { spawnErrorToast } from "@sustainability/fundamental"
import i18next from "i18next"
import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

type State = {
	materials?: Material[]
}

type Actions = {
	fetchNotTransferedMaterials: () => Promise<void>
}

export const useSelectionOfMaterialFormStore = create<State & Actions>()(
	immer(set => ({
		materials: undefined,

		fetchNotTransferedMaterials: async () => {
			try {
				const materialData = await materialsApi.get(false)

				set(state => {
					state.materials = materialData.items
				})
			} catch {
				spawnErrorToast(
					i18next.t(
						"features:selectionOfMaterialForm.errorMessages.fetchNotTransferedMaterialsError",
					),
				)
			}
		},
	})),
)
