import { CertificateStatus } from "@shared/models"
import { icon, PillType } from "@sustainability/fundamental"
import i18next from "i18next"

type CertificateStatusPillData = {
	icon: string
	color: PillType
	text: string
}

export const certificatesStatusPillData = (
	status: CertificateStatus,
): CertificateStatusPillData =>
	(
		({
			[CertificateStatus.Claimed]: {
				icon: icon.mdiCheck,
				color: "positive",
				text: i18next.t(
					"features:certificatesList.certificatesTableHeaders.transactionNumber.statuses.claimed",
				),
			},
			[CertificateStatus.PartiallyClaimed]: {
				icon: icon.mdiTimerSandComplete,
				color: "positive",
				text: i18next.t(
					"features:certificatesList.certificatesTableHeaders.transactionNumber.statuses.partiallyClaimed",
				),
			},
			[CertificateStatus.Pending]: {
				icon: icon.mdiTimerSandEmpty,
				color: "neutral",
				text: i18next.t(
					"features:certificatesList.certificatesTableHeaders.transactionNumber.statuses.pending",
				),
			},
		}) satisfies Record<CertificateStatus, CertificateStatusPillData>
	)[status]
