import { getTransactionHashScanLink } from "@carbonbank/utils"
import { CertificateStatusPill } from "@shared/ui/certificate-status-pill"
import {
	Button,
	ExternalLink,
	Icon,
	icon,
	useSortableTable,
} from "@sustainability/fundamental"
import { TFunction } from "i18next"

import { FormattedCertificatesForMaterialItem } from "../store/types"

const CAMMON_VALUE_COLUMNS_CLASS_NAME =
	"whitespace-nowrap overflow-hidden text-ellipsis"

export const useCertificatesTableConfig = (t: TFunction<"features", string>) =>
	useSortableTable<FormattedCertificatesForMaterialItem>([
		{
			id: "projectReference",
			label: t("projectReference"),
			sortable: false,
			render: ({ projectReference }) => (
				<div className={CAMMON_VALUE_COLUMNS_CLASS_NAME}>
					{projectReference}
				</div>
			),
			valueClassName: "lg:w-[132px] xl:w-[216px] ",
			headerClassName: "lg:w-[132px] xl:w-[216px]",
		},
		{
			id: "date",
			label: t("dateOfIssue"),
			sortable: false,
			render: ({ date }) => (
				<div className={CAMMON_VALUE_COLUMNS_CLASS_NAME}>{date}</div>
			),
			valueClassName: "lg:w-[141px] xl:w-[224px]",
			headerClassName: "lg:w-[141px] xl:w-[224px]",
		},
		{
			id: "quantity",
			label: t("quantity"),
			sortable: false,
			render: ({ quantity }) => (
				<div className={CAMMON_VALUE_COLUMNS_CLASS_NAME}>
					{quantity}
				</div>
			),
			valueClassName: "lg:text-right lg:w-[141px] xl:w-[224px]",
			headerClassName: "lg:text-right lg:w-[141px] xl:w-[224px]",
		},
		{
			id: "carbonSavingsQuantity",
			label: t("carbonSavings"),
			sortable: false,
			render: ({ carbonSavingsQuantity }) => (
				<div className={CAMMON_VALUE_COLUMNS_CLASS_NAME}>
					{carbonSavingsQuantity}
				</div>
			),
			valueClassName: "lg:text-right lg:w-[141px] xl:w-[224px]",
			headerClassName: "lg:text-right lg:w-[141px] xl:w-[224px]",
		},
		{
			id: "transactionId",
			label: t("transactionNumber.title"),
			sortable: false,
			render: ({ transactionId, transactionLink, status }) => (
				<div
					className={`lg:w-[141px] ${CAMMON_VALUE_COLUMNS_CLASS_NAME}`}
				>
					{transactionId && transactionLink ? (
						<ExternalLink href={transactionLink}>
							{transactionId}
						</ExternalLink>
					) : (
						<CertificateStatusPill status={status} />
					)}
				</div>
			),
			valueClassName: "lg:w-[141px] xl:w-[224px]",
			headerClassName: "lg:w-[141px] xl:w-[224px]",
		},
		{
			label: t("actions.title"),
			sortable: false,
			render: ({ transactionId }) => (
				<div className="flex gap-[12px] justify-end items-center lg:flex-row xs:flex-col">
					{transactionId && (
						<ExternalLink
							href={getTransactionHashScanLink(transactionId)}
						>
							<Button variant="tertiary">
								<div className="flex items-center gap-2">
									<Icon path={icon.mdiOpenInNew} />
									{t("actions.showLink")}
								</div>
							</Button>
						</ExternalLink>
					)}
					<Button
						className="lg:w-[110px] xs:w-full"
						variant="negative"
						icon={icon.mdiDelete}
					>
						{t("actions.delete")}
					</Button>
				</div>
			),
			valueClassName: "lg:text-right lg:w-[256px]",
			headerClassName: "lg:text-right lg:w-[256px]",
		},
	])
