import { CertificateDetailsFormInputs } from "@shared/stores"
import { TFunction } from "i18next"
import * as Yup from "yup"

export const useCertificateDetailsValidationSchema = (
	t: TFunction<"features", "selectionOfMaterialForm">,
): Yup.Schema<CertificateDetailsFormInputs> => {
	return Yup.object().shape({
		project: Yup.string().required(t("errorMessages.projectRequired")),
		companyName: Yup.string().required(
			t("errorMessages.companyNameRequired"),
		),
		street: Yup.string().required(t("errorMessages.streetRequired")),
		zipCode: Yup.string().required(t("errorMessages.zipCodeRequired")),
		city: Yup.string().required(t("errorMessages.cityRequired")),
		country: Yup.string().required(t("errorMessages.countryRequired")),
	})
}
