import { mapUnitToLocalizedUnit } from "@carbonbank/shared/lib"
import { nameof } from "@carbonbank/shared/lib/utility-types/table"
import { formatIntNumber } from "@carbonbank/utils"
import { GetProjectDetailResponse } from "@shared/api"
import { Unit } from "@shared/api/types"
import { sortMeasurement } from "@shared/lib/table-helpers"
import { getProjectsDetailsUrl } from "@shared/routes"
import {
	icon,
	Icon,
	Pill,
	TextRegular,
	useSortableTable,
} from "@sustainability/fundamental"
import { TFunction } from "i18next"
import { Link } from "react-router-dom"

import { pill } from "../constants"
import { renderQuantity } from "../utils"

const CEMENT_QUANTITY_DATA_CY = "cement-quantity"
const CONCRETE_QUANTITY_DATA_CY = "concrete-quantity"

export const useTable = (t: TFunction<"features", string>) =>
	useSortableTable<GetProjectDetailResponse>(
		[
			{
				id: "reference",
				label: t("reference"),
				render: ({ reference, customerReference }) => (
					<div className="max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis">
						<TextRegular data-cy="project-reference">
							{reference}
						</TextRegular>
						{customerReference && (
							<TextRegular data-cy="customer-reference">
								({customerReference})
							</TextRegular>
						)}
					</div>
				),
				headerClassName: "lg:w-[400px] xl:w-[600px]",
				valueClassName: "lg:w-[400px] xl:w-[600px]",
			},
			{
				label: t("quantity"),
				render: ({ cementQuantity, concreteQuantity }) => (
					<>
						{cementQuantity &&
							renderQuantity(
								cementQuantity,
								CEMENT_QUANTITY_DATA_CY,
							)}
						{cementQuantity && concreteQuantity && " / "}
						{concreteQuantity &&
							renderQuantity(
								concreteQuantity,
								CONCRETE_QUANTITY_DATA_CY,
							)}
					</>
				),
				sortable: false,
				headerClassName: "min-w-[120px]",
				valueClassName: "min-w-[120px]",
			},
			{
				id: "savings",
				label: t("savings"),
				sortFunction: sortMeasurement("savings"),
				render: ({ savings }) => (
					<div className="max-w-[200px] whitespace-nowrap overflow-hidden text-clip">
						<TextRegular data-cy="savings-amount">
							{formatIntNumber(savings.amount)}
						</TextRegular>{" "}
						<TextRegular>
							{mapUnitToLocalizedUnit(Unit.KgCo2)}
						</TextRegular>
					</div>
				),
			},
			{
				id: "status",
				label: t("status.title"),
				render: ({ status }) => (
					<div className="flex justify-start">
						<Pill
							data-cy="project-status"
							labelIcon={pill.icon[status]}
							type={pill.color[status]}
							text={t(`status.options.${status}`)}
							size="large"
						/>
					</div>
				),
				sortable: false,
			},
			{
				label: undefined,
				valueClassName: "text-left md:text-right max-md:hidden",
				headerClassName: "text-left md:text-right max-md:hidden",
				sortable: false,
				render: row => (
					<Link
						data-cy="project-detail-link"
						to={getProjectsDetailsUrl(row.id)}
						className="inline-block"
					>
						<Icon path={icon.mdiChevronRight} />
					</Link>
				),
			},
		],
		undefined,
		{
			prefix: "projects-list-table-row",
			postfixFieldName: nameof<GetProjectDetailResponse>("id"),
		},
		{ enableLocalSorting: true },
	)
