import NoProjectsImage from "@shared/assets/no-projects.svg"
import { H1, TextRegular } from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

export const NoProjects = () => {
	const { t } = useTranslation("features", {
		keyPrefix: "projectsList.noProjects",
	})
	return (
		<div
			className="flex flex-col items-center gap-5 w-[450px]"
			data-cy="no-projects"
		>
			<img src={NoProjectsImage} />
			<H1 className="text-center">{t("createCertificate")}</H1>
			<TextRegular className="text-foreground_faded text-center">
				{t("description")}
			</TextRegular>
		</div>
	)
}
