import { CertificateDetailsSection } from "./certificate-details-section"
import { ProvideCertificatesDetailsSection } from "./provide-cectificates-details-section"

interface CertificateDetailsContentProps {
	featureName: string
}

export const CertificateDetailsContent: React.FC<
	CertificateDetailsContentProps
> = ({ featureName }) => {
	return (
		<div className="flex flex-col gap-y-[48px]">
			<CertificateDetailsSection featureName={featureName} />
			<ProvideCertificatesDetailsSection featureName={featureName} />
		</div>
	)
}
