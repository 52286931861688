export const getStepperItemState = (
	currentStep: number,
	targetStep: number,
	isValid: boolean = true,
) => {
	if (currentStep === targetStep) {
		return "active"
	}

	if (currentStep < targetStep) {
		return "inactive"
	}

	if (currentStep > targetStep && !isValid) {
		return "failed"
	}

	return "completed"
}
