import { Unit } from "@shared/api/types"
import { mapUnitToLocalizedUnit } from "@shared/lib"
import {
	CertificateType,
	SelectionOfMaterialFormInputs,
	useCertificateCreationFormStore,
} from "@shared/stores"
import {
	Label,
	LabelRegular,
	TableBase,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
	TextMedium,
} from "@sustainability/fundamental"
import { useFormikContext } from "formik"
import { useTranslation } from "react-i18next"

interface SelectionOfMaterialSummaryProps {
	featureName: string
}

export const SelectionOfMaterialSummary: React.FC<
	SelectionOfMaterialSummaryProps
> = ({ featureName }) => {
	const { t } = useTranslation("features", {
		keyPrefix: `${featureName}.summary`,
	})

	const selectionOfCertificateTypeFormState = useCertificateCreationFormStore(
		state => state.selectionOfCertificateTypeFormState,
	)

	const { values } = useFormikContext<SelectionOfMaterialFormInputs>()

	if (!selectionOfCertificateTypeFormState) {
		return <></>
	}

	return (
		<>
			<Label title={t("certificateTypeTitle")}>
				{selectionOfCertificateTypeFormState.certificateType ===
				CertificateType.CustomerProject
					? t("customerProjectTitle")
					: t("internalProjectOrProductTitle")}
			</Label>
			<Label title={t("projectReferenceTitle")}>
				{selectionOfCertificateTypeFormState.project}
			</Label>
			<TableBase className="ml-[-13px] w-[calc(100%+26px)]">
				<TableHeader>
					<TableHeaderCell className="w-[75%]">
						{t("materialHeaderTitle")}
					</TableHeaderCell>
					<TableHeaderCell className="w-[25%] text-right">
						{t("quantityHeaderTitle", {
							unit: mapUnitToLocalizedUnit(values.unit),
						})}
					</TableHeaderCell>
				</TableHeader>
				<TableBody>
					<TableRow>
						<TableCell className="w-[75%]">
							<TextMedium>
								{values.materialDescription}
							</TextMedium>
							<LabelRegular className="text-foreground_faded">
								({values.material})
							</LabelRegular>
						</TableCell>
						<TableCell className="w-[25%] text-right">
							{values.quantity}
						</TableCell>
					</TableRow>
				</TableBody>
			</TableBase>
			<Label
				title={t("totalCarbonSavingsTitle", {
					unit: mapUnitToLocalizedUnit(Unit.KgCo2),
				})}
			>
				{values.savings}
			</Label>
		</>
	)
}
