import {
	certificateCreation,
	certificateDetailsForm,
	materialDetails,
	materials,
	selectionOfCertificateTypeForm,
	selectionOfMaterialForm,
} from "@shared/routes"
import { Layout } from "@shared/ui"
import { RouteObject } from "react-router-dom"

import { CertificateCreationPage } from "../certificate-creation"
import { ManageCertificatesPage } from "../manage-certificates"
import { MaterialDetailsPage } from "../material-details"

export default [
	{
		path: materials,
		element: <Layout />,
		children: [
			{
				index: true,
				element: <ManageCertificatesPage />,
			},
			{
				path: materialDetails,
				children: [
					{
						index: true,
						element: <MaterialDetailsPage />,
					},
					{
						path: selectionOfCertificateTypeForm,
						element: <CertificateCreationPage />,
					},
					{
						path: selectionOfMaterialForm,
						element: <CertificateCreationPage />,
					},
					{
						path: certificateDetailsForm,
						element: <CertificateCreationPage />,
					},
					{
						path: certificateCreation,
						children: [
							{
								path: selectionOfCertificateTypeForm,
								element: <CertificateCreationPage />,
							},
							{
								path: selectionOfMaterialForm,
								element: <CertificateCreationPage />,
							},
							{
								path: certificateDetailsForm,
								element: <CertificateCreationPage />,
							},
						],
					},
				],
			},
		],
	},
] satisfies RouteObject[]
