import { Yup } from "@carbonbank/yup"
import { Unit } from "@shared/api/types"
import { SelectionOfMaterialFormInputs } from "@shared/stores"
import { TFunction } from "i18next"
import { object, ref, Schema } from "yup"

export const useValidationSchema = (
	t: TFunction<"features", "selectionOfMaterialForm">,
): Schema<SelectionOfMaterialFormInputs> =>
	object().shape({
		material: Yup.string().required(t("errorMessages.materialRequired")),
		materialDescription: Yup.string().required(
			t("errorMessages.materialDescriptionRequired"),
		),
		customerId: Yup.string().required(t("errorMessages.customerIdRequired")),
		quantity: Yup.number()
			.required(t("errorMessages.quantityRequired"))
			.positive(t("errorMessages.quantityPositive"))
			.max(
				ref("totalQuantity"),
				t("errorMessages.quantityLargerThanAvailable"),
			),
		totalQuantity: Yup.number().required(
			t("errorMessages.totalQuantityRequired"),
		),
		savings: Yup.number().required(t("errorMessages.savingsRequired")),
		unit: Yup.mixed<Unit>().required(t("errorMessages.unitRequired")),
	})
