import { Plant } from "@carbonbank/api"
import { FilterConfig } from "@sustainability/fundamental"
import { TFunction } from "i18next"

const mapInputToValue = (input: Plant[]) =>
	input.map(value => ({ value: value.plantNumber!, label: value.plantName! }))

export const carbonSavingsFilterConfig = (
	input: Plant[],
	t: TFunction<"cs", "carbonSavings.page">,
): FilterConfig[] => [
	{
		label: t("location"),
		type: "select",
		values: mapInputToValue(input),
		name: "plantNumber",
	},
]
