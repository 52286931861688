import { RoundedBox } from "@carbonbank/shared/ui"
import { useLoadingStore } from "@shared/stores"
import { FeatureToLoading } from "@shared/stores/loading/types"
import { CardHeader, clx } from "@sustainability/fundamental"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useInvoicesForMaterialStore } from "../store"
import { InvoicesTable } from "./invoices-table"

interface InvoicesListProps {
	className?: string
	pageName: string
	contractNumber: string
	materialNumber: string
	isLoading: boolean
}

export const InvoicesList: React.FC<InvoicesListProps> = ({
	className,
	pageName,
	contractNumber,
	materialNumber,
	isLoading,
}) => {
	const FEATURE_NAME = "invoicesList"
	const featureToLoading: FeatureToLoading = {
		page: pageName,
		feature: FEATURE_NAME,
	}

	const { t } = useTranslation("features", { keyPrefix: FEATURE_NAME })

	const [fetchInvoicesForMaterialItem, resetStore] =
		useInvoicesForMaterialStore(state => [
			state.fetchInvoicesForMaterialItem,
			state.resetStore,
		])

	const [addFeatureToLoading, removeFeatureFromLoading] = useLoadingStore(
		state => [state.addFeatureToLoading, state.removeFeatureFromLoading],
	)

	const fetchInvoicesForMaterialItemData = async () => {
		addFeatureToLoading(featureToLoading)

		await fetchInvoicesForMaterialItem(contractNumber, materialNumber)

		removeFeatureFromLoading(featureToLoading)
	}

	useEffect(() => {
		fetchInvoicesForMaterialItemData()

		return () => resetStore()
	}, [])

	if (isLoading) {
		return <></>
	}

	return (
		<RoundedBox
			data-cy="invoices-list-for-material-container"
			className={clx("py-4 sm:py-6", className)}
		>
			<CardHeader
				data-cy="invoices-list-for-material-header"
				className="pl-4 md:pl-6"
			>
				{t("title")}
			</CardHeader>
			<InvoicesTable
				featureName={FEATURE_NAME}
				contractNumber={contractNumber}
				materialNumber={materialNumber}
			/>
		</RoundedBox>
	)
}
