import { useLoadingStore } from "@shared/stores"
import { FeatureToLoading } from "@shared/stores/loading/types"
import { Button, CardHeader, Grid, icon } from "@sustainability/fundamental"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useActivityHistoryStore } from "../store"
import { AccountabilityModal } from "./accountability-modal"
import { ActivityHistoryItemCard } from "./activity-history-item-card"
import { TraceabilityModal } from "./traceability-modal"

export type ActivityHistoryProps = {
	pageName: string
	projectId: string
	isLoading: boolean
}

export const ActivityHistory: React.FC<ActivityHistoryProps> = ({
	pageName,
	projectId,
	isLoading,
}) => {
	const FEATURE_NAME = "activityHistory"
	const feature: FeatureToLoading = {
		page: pageName,
		feature: FEATURE_NAME,
	}

	const { t } = useTranslation("features", {
		keyPrefix: FEATURE_NAME,
	})

	const [accountabilityModalOpened, setAccountabilityModalOpened] =
		useState(false)
	const [traceabilityModalOpened, setTraceabilityModalOpened] =
		useState(false)

	const [addFeatureToLoading, removeFeatureFromLoading] = useLoadingStore(
		state => [state.addFeatureToLoading, state.removeFeatureFromLoading],
	)
	const [activityHistory, fetchActivityHistory] = useActivityHistoryStore(
		state => [state.activityHistory, state.fetchActivityHistory],
	)

	const fetchActivityHistoryData = async () => {
		addFeatureToLoading(feature)
		await fetchActivityHistory(projectId)
		removeFeatureFromLoading(feature)
	}

	useEffect(() => {
		if (projectId) {
			console.log({ projectId })
			fetchActivityHistoryData()
		}
	}, [projectId])

	if (isLoading) return null

	return (
		<>
			<div className="flex flex-col gap-6">
				<div className="px-6 w-full text-wrap">
					<CardHeader
						aside={
							<>
								<Button
									className="max-lg:px-0"
									variant="tertiary"
									icon={icon.mdiInformationOutline}
									onClick={() =>
										setAccountabilityModalOpened(true)
									}
								>
									{t("accountabilityModal.label")}
								</Button>
								<Button
									className="max-lg:px-0"
									variant="tertiary"
									icon={icon.mdiInformationOutline}
									onClick={() =>
										setTraceabilityModalOpened(true)
									}
								>
									{t("traceabilityModal.label")}
								</Button>
							</>
						}
						wrapAside
						containerClassname="!mb-0"
					>
						{t("title")}
					</CardHeader>
				</div>
				<Grid
					size={1}
					lgSize={4}
					mdSize={3}
					className="px-0 flex flex-col gap-4"
					data-cy="activity-history-list"
				>
					{activityHistory?.measurements.map(measurement => (
						<ActivityHistoryItemCard
							key={measurement.id}
							featureName={FEATURE_NAME}
							type="measurement"
							{...measurement}
						/>
					))}
					{activityHistory?.transactions.map(transaction => (
						<ActivityHistoryItemCard
							key={transaction.id}
							featureName={FEATURE_NAME}
							type="transaction"
							{...transaction}
						/>
					))}
				</Grid>
			</div>
			<TraceabilityModal
				isModalOpen={traceabilityModalOpened}
				onClose={() => setTraceabilityModalOpened(false)}
			/>
			<AccountabilityModal
				isModalOpen={accountabilityModalOpened}
				onClose={() => setAccountabilityModalOpened(false)}
			/>
		</>
	)
}
