import { RoleType } from "@carbonbank/api"
import { useAuthStore, useFilterStore } from "@carbonbank/store"
import { useUserSettingsStore } from "@carbonbank/store/useUserSettingsStore"
import { supportedLanguages } from "@shared/i18n"
import { resolveToSupportedLanguage } from "@shared/i18n"
import {
	Avatar,
	Gavel,
	LabelRegular,
	MenuItemConfig,
	UserMenu,
	icon,
} from "@sustainability/fundamental"
import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"

export const Profile = () => {
	const { t } = useTranslation("cb", {
		keyPrefix: "layout.userMenu",
	})

	const { t: tLanguages } = useTranslation("cb", {
		keyPrefix: "common.languages",
	})

	const [currentLanguage, updateLanguage] = useUserSettingsStore(store => [
		store.currentLanguage,
		store.updateLanguage,
	])

	const [loginState, updateProfile, userRoles, login, logout] = useAuthStore(
		store => [
			store.loginState,
			store.updateUserProfile,
			store.userRoles,
			store.login,
			store.logout,
		],
	)

	const [customerFilterData, fetchCustomerFilterData] = useFilterStore(
		store => [store.customerFilterData, store.fetchCustomerFilterData],
	)

	const availableLanguages = supportedLanguages.reduce(
		(items, languageCode) => {
			items.push({
				key: languageCode,
				title: tLanguages(languageCode),
			})
			return items
		},
		[] as MenuItemConfig[],
	)

	const selectedLanguage = resolveToSupportedLanguage(
		currentLanguage ?? supportedLanguages[0],
		supportedLanguages,
	)

	useEffect(() => {
		const shouldFetchCustomerAccounts =
			loginState.loggedIn &&
			userRoles
				?.map(x => x.roleType)
				.includes(RoleType.CarbonBankCustomer)

		if (shouldFetchCustomerAccounts) {
			fetchCustomerFilterData()
		}
	}, [loginState.loggedIn, userRoles])

	const carbonBankCustomerItems = useMemo(() => {
		if (!customerFilterData) {
			return []
		}

		const formattedUserAccounts = customerFilterData.map(
			x => `${x.customerNumber} - ${x.customerName}`,
		)

		const accountsItem: MenuItemConfig = {
			key: "accounts",
			title: t("accounts"),
			icon: icon.mdiAccountCircleOutline,
			subtitle: formattedUserAccounts,
			subtitleClassname:
				"whitespace-nowrap overflow-hidden text-ellipsis",
		}

		return [accountsItem]
	}, [customerFilterData])

	const handleLanguageChanged = async (languageCode: string) => {
		await updateProfile({ defaultLocale: languageCode })
		updateLanguage(languageCode)
	}

	const commonItems: MenuItemConfig[] = useMemo(
		() => [
			{
				key: "about",
				title: t("about.title"),
				icon: icon.mdiInformationOutline,
				items: [
					{
						key: "terms_and_conditions",
						title: t("about.termsAndConditions"),
						customIcon: <Gavel />,
						onClicked: () => {
							window.location.href =
								"https://www.heidelbergmaterials.com/terms-and-conditions"
						},
					},
					{
						key: "privacy_policy",
						title: t("about.privacyPolicy"),
						icon: icon.mdiShieldAlertOutline,
					},
					{
						key: "open_source",
						title: t("about.openSourceAttribution"),
						icon: icon.mdiClipboardTextOutline,
					},
					{
						key: "imprint",
						title: t("about.imprint"),
						icon: icon.mdiCopyright,
					},
				],
			},
			{
				key: "language",
				title: t("language.title"),
				footer: t("language.footer"),
				icon: icon.mdiTranslate,
				type: "select",
				onClicked: handleLanguageChanged,
				selectedItem: selectedLanguage,
				items: availableLanguages,
			},
		],
		[currentLanguage, t],
	)

	const loggedInItems: MenuItemConfig[] = useMemo(
		() => [
			{
				key: "user",
				title: loginState.decodedToken?.name ?? "",
				customIcon: (
					<Avatar name={loginState.decodedToken?.name ?? ""} />
				),
				subtitle: loginState.decodedToken?.email ?? "",
			},
			...carbonBankCustomerItems,
			{
				key: "subscribe",
				title: t("subscribeToFeeback"),
				icon: icon.mdiEmailMarkAsUnread,
			},
			...commonItems,
			{
				key: "logout",
				title: t("logout"),
				icon: icon.mdiLogout,
				iconClasses: "text-red-400",
				onClicked: logout,
			},
		],
		[carbonBankCustomerItems, commonItems, loginState.loggedIn],
	)

	const loggedOutItems: MenuItemConfig[] = useMemo(
		() => [
			...commonItems,
			{
				key: "login",
				title: t("login"),
				icon: icon.mdiLogin,
				iconClasses: "text-red-400",
				onClicked: login,
			},
		],
		[commonItems, loginState.loggedIn],
	)

	return (
		<UserMenu
			loggedIn={loginState.loggedIn}
			items={loginState.loggedIn ? loggedInItems : loggedOutItems}
			footer={
				<div className="flex flex-col items-center">
					<LabelRegular className="text-foreground_faded">
						©2024 HeidelbergMaterials. All rights reserved.
					</LabelRegular>
					<LabelRegular className="text-foreground_faded">
						<a href="heidelbergmaterials.com">Imprint</a>
					</LabelRegular>
				</div>
			}
		/>
	)
}
