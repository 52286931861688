import { formatDate } from "@carbonbank/utils"
import { FeatureProps } from "@shared/models/featureProps"
import {
	DetailsCard,
	ExternalLink,
	H3,
	LabelRegular,
	TextMedium,
	TextRegular,
} from "@sustainability/fundamental"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { ActivityHistoryItem } from "../../model"
import { ProofIdsModal } from "../proof-ids-modal"
import { getProofLink } from "../utils"

type ActivityHistoryItemProps = FeatureProps &
	ActivityHistoryItem & {
		type: "measurement" | "transaction"
	}

const PROOF_IDS_LIMIT = 1

export const ActivityHistoryItemCard: React.FC<ActivityHistoryItemProps> = ({
	type,
	title,
	description,
	image,
	startDate,
	endDate,
	proofIds,
}) => {
	const { t } = useTranslation("features", {
		keyPrefix: "activityHistory.item",
	})

	const isTransaction = type === "transaction"

	const [proofIdsModalOpened, setProofIdsModalOpened] = useState(false)

	const exceedsLimit =
		(isTransaction && Object.values(proofIds).length > PROOF_IDS_LIMIT) ||
		(!isTransaction &&
			Array.isArray(proofIds) &&
			proofIds.length > PROOF_IDS_LIMIT)
	return (
		<>
			<DetailsCard
				data-cy="activity-history-card"
				className="flex flex-col gap-6"
			>
				<H3 data-cy="activity-history-title" className="h-12">
					{title}
				</H3>
				<div className="flex flex-col gap-6">
					<img
						src={image}
						alt=""
						data-cy="activity-history-image"
						className="w-46 h-46 object-cover mx-auto"
					/>
					<div className="flex flex-col">
						<LabelRegular
							className="text-foreground_faded"
							data-cy="activity-history-description"
						>
							{t("description")}
						</LabelRegular>
						<TextMedium className="min-h-[80px]">
							{description}
						</TextMedium>
					</div>
					<div className="flex flex-col">
						<LabelRegular data-cy="activity-history-time">
							{t("time")}
						</LabelRegular>
						<TextMedium>
							{startDate || endDate ? (
								<>
									{formatDate(startDate.toString())} -{" "}
									{formatDate(endDate.toString())}
								</>
							) : (
								"-"
							)}
						</TextMedium>
					</div>
					<div className="flex flex-col">
						<LabelRegular className="text-foreground_faded">
							{isTransaction
								? t("transactionId")
								: t("measurementId")}
						</LabelRegular>
						{exceedsLimit && (
							<div data-cy="activity-history-proof-id-show-ids">
								<TextRegular
									className="h-10 w-fit underline text-primary hover:no-underline"
									onClick={() => setProofIdsModalOpened(true)}
								>
									{t("showIds")}
								</TextRegular>
								<ProofIdsModal
									type={type}
									proofIds={proofIds}
									isModalOpen={proofIdsModalOpened}
									onClose={() =>
										setProofIdsModalOpened(false)
									}
								/>
							</div>
						)}
						{!exceedsLimit && (
							<ExternalLink
								data-cy="activity-history-proof-id-link"
								href={getProofLink(
									type,
									Array.isArray(proofIds)
										? proofIds[0]
										: typeof proofIds === "object"
											? Object.values(proofIds)[0]
											: "",
								)}
							>
								{Array.isArray(proofIds) && proofIds[0]}
								{typeof proofIds === "object" &&
									Object.values(proofIds)[0]}
							</ExternalLink>
						)}
					</div>
				</div>
			</DetailsCard>
		</>
	)
}
