import { carbonBankWithAuthClient } from "@carbonbank/api"

import { CreateCertificateData, PreviousProjectData } from "./types"

export const certificatesApi = {
	getPreviousProjects: (): Promise<PreviousProjectData> =>
		carbonBankWithAuthClient
			.get("/certificates/project-references/names")
			.then(response => response.data),
	create: (data: CreateCertificateData): Promise<void> =>
		carbonBankWithAuthClient.post("/certificates", data),
}
