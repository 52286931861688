import { create } from "zustand"
import { immer } from "zustand/middleware/immer"

import { FeatureToLoading } from "./types"

type State = {
	featuresToLoading: FeatureToLoading[]
}

type Actions = {
	isPageLoading: (page: string) => boolean
	isFeatureLoading: (feature: string) => boolean
	addFeatureToLoading: (feature: FeatureToLoading) => void
	removeFeatureFromLoading: (feature: FeatureToLoading) => void
}

export const useLoadingStore = create<State & Actions>()(
	immer((set, get) => ({
		featuresToLoading: [],

		isPageLoading: (page: string) => {
			return get().featuresToLoading.some(x => x.page === page)
		},
		isFeatureLoading: (feature: string) => {
			return get().featuresToLoading.some(x => x.feature === feature)
		},
		addFeatureToLoading: (feature: FeatureToLoading) => {
			set(state => {
				state.featuresToLoading.push(feature)
			})
		},
		removeFeatureFromLoading: (feature: FeatureToLoading) => {
			set(state => {
				state.featuresToLoading = state.featuresToLoading.filter(
					x =>
						x.page === feature.page &&
						x.feature !== feature.feature,
				)
			})
		},
	})),
)
