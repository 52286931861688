import { materials } from "@shared/routes"
import { useLoadingStore, useProjectDetailCertificates } from "@shared/stores"
import { Button, icon, PageHeader } from "@sustainability/fundamental"
import { useEffect } from "react"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { useProjectDetailHeaderStore } from "../store"
import { ProjectDetailHeaderKpis } from "./kpi"

interface ProjectDetailHeaderProps {
	pageName: string
	projectId: string
	isLoading: boolean
}

export const ProjectDetailHeader: React.FC<ProjectDetailHeaderProps> = ({
	pageName,
	projectId,
	isLoading,
}) => {
	const FEATURE_NAME = "projectDetailHeader"
	const navigate = useNavigate()

	const { t } = useTranslation("features", {
		keyPrefix: "projectDetailHeader",
	})

	const [projectDetail, fetchProjectDetailData, resetStore] =
		useProjectDetailHeaderStore(state => [
			state.projectDetail,
			state.fetchProjectDetail,
			state.resetStore,
		])

	const [certificates, fetchCertificates, downloadAllCertificates] =
		useProjectDetailCertificates(state => [
			state.certificates,
			state.fetchCertificatesForProjectDetail,
			state.downloadAllCertificates,
		])

	const [addFeatureToLoading, removeFeatureFromLoading] = useLoadingStore(
		state => [state.addFeatureToLoading, state.removeFeatureFromLoading],
	)

	const fetchProjectDetail = async () => {
		addFeatureToLoading({ page: pageName, feature: FEATURE_NAME })

		await fetchProjectDetailData(projectId)

		removeFeatureFromLoading({ page: pageName, feature: FEATURE_NAME })
	}

	useEffect(() => {
		fetchProjectDetail()
		fetchCertificates(projectId)

		return () => resetStore()
	}, [])

	if (isLoading || !projectDetail) {
		return <></>
	}

	return (
		<>
			<PageHeader
				backToPageUrlOnClick={() => navigate(materials)}
				backToPageLabel={t("backToManageCertificates")}
				data-cy="project-detail-header"
				className="pl-5 grid-cols-1 grid-rows-1 sm:grid-rows-1 sm:grid-cols-[auto_1fr]"
				aside={
					<Button
						data-cy="download-all-certificates"
						className="w-full sm:w-fit"
						onClick={async () => await downloadAllCertificates()}
						icon={icon.mdiDownloadOutline}
						disabled={!certificates.length}
					>
						{t("downloadAllCertificates")}
					</Button>
				}
				asideClassname="sm:inline-flex sm:justify-end max-md:w-full max-md:justify-center max-md:pl-4 pr-4"
				wrapAside
			>
				{projectDetail.reference}
			</PageHeader>
			<ProjectDetailHeaderKpis data={projectDetail} />
		</>
	)
}
