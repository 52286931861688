import { getProjectsDetailsUrl } from "@carbonbank/shared/routes"
import { RoundedBox } from "@carbonbank/shared/ui"
import { NoProjects } from "@shared/ui/no-projects"
import {
	CardHeader,
	Loading,
	spawnErrorToast,
	Table,
} from "@sustainability/fundamental"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { useBuildingProjectsStore } from "../store"
import { useTable } from "./useTable"

export const ProjectsList = () => {
	const navigate = useNavigate()
	const { t } = useTranslation("features", {
		keyPrefix: "projectsList",
	})
	const { t: tTable } = useTranslation("features", {
		keyPrefix: "projectsList.table",
	})
	const tableConfig = useTable(tTable)
	const [projects, fetchProjects] = useBuildingProjectsStore(state => [
		state.projects,
		state.fetchProjects,
	])

	const [loading, setLoading] = useState<boolean>(false)

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true)
				await fetchProjects()
			} catch {
				spawnErrorToast(tTable("fetchError"))
			} finally {
				setLoading(false)
			}
		}
		fetchData()
	}, [])

	return (
		<RoundedBox
			data-cy="existing-projects-card"
			className="flex flex-col py-4 md:py-6"
		>
			{loading || projects?.length ? (
				<div className="flex flex-col justify-center w-full lg:gap-6 lg:mx-3">
					<CardHeader className="pl-4 lg:pl-3 mt-3">
						{t("title")}
					</CardHeader>
					<div className="flex justify-center w-full">
						{loading ? (
							<Loading
								data-cy="projects-loading-indicator"
								size="large"
							/>
						) : (
							<Table
								data-cy="projects-list-table"
								onRowClick={row =>
									navigate(getProjectsDetailsUrl(row.id))
								}
								config={tableConfig}
								data={projects}
							/>
						)}
					</div>
				</div>
			) : (
				<div className="flex w-full justify-center" data-cy="test-me">
					<NoProjects />
				</div>
			)}
		</RoundedBox>
	)
}
