import {
	Button,
	Modal,
	ModalContent,
	ModalFooter,
	ModalTitle,
	TextRegular,
} from "@sustainability/fundamental"
import { useTranslation } from "react-i18next"

import { TransactionRow } from "./ui/transaction-row"

interface ProofIdsModalProps {
	type: "measurement" | "transaction"
	proofIds: Record<string, string> | string[]
	isModalOpen: boolean
	onClose: () => void
}

export const ProofIdsModal: React.FC<ProofIdsModalProps> = ({
	type,
	proofIds,
	isModalOpen,
	onClose,
}) => {
	const { t } = useTranslation("features", {
		keyPrefix: "activityHistory.proofIdsModal",
	})

	return (
		<Modal
			data-cy="proof-ids-modal"
			className="!w-[700px]"
			open={isModalOpen}
			onClose={onClose}
		>
			<ModalTitle data-cy="proof-ids-modal-title" className="pt-0">
				{type === "transaction"
					? t("titleTransactions")
					: t("titleMeasurements")}
			</ModalTitle>
			<ModalContent data-cy="proof-ids-modal-content">
				<div className="flex flex-col gap-6">
					<TextRegular className="text-foreground">
						{t("paragraph")}
					</TextRegular>
					<div>
						{Array.isArray(proofIds)
							? proofIds.map((proofId, index) => (
									<TransactionRow
										key={index}
										proofId={proofId}
									/>
								))
							: typeof proofIds === "object"
								? Object.entries(proofIds).map(
										([material, proofId], index) => (
											<TransactionRow
												key={index}
												material={material}
												proofId={proofId}
											/>
										),
									)
								: null}
					</div>
				</div>
			</ModalContent>
			<ModalFooter
				data-cy="proof-ids-modal-footer"
				className="max-md:flex-col max-md:w-full"
			>
				<Button
					onClick={onClose}
					data-cy="proof-ids-modal-button-close"
				>
					{t("close")}
				</Button>
			</ModalFooter>
		</Modal>
	)
}
