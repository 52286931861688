import {
	PaginationRequest,
	PaginationResponse,
} from "@carbonbank/shared/api/types"
import { StateCreator } from "zustand"

const INITIAL_PAGE = 0
const INITIAL_PAGE_SIZE = 10
const INITIAL_TOTAL_COUNT = 0

const INITIAL_PAGINATION_REQUEST: PaginationRequest = {
	page: INITIAL_PAGE,
	pageSize: INITIAL_PAGE_SIZE,
}

export type PaginationState = {
	page: number
	pageSize: number
	totalCount: number
	canLoadMore: boolean
	paginationRequest: PaginationRequest
}

export type PaginationActions = {
	updatePagination: (
		pagination: PaginationResponse,
		availableCount: number,
	) => void
	resetPagination: () => void
}

export const usePaginationStore: StateCreator<
	PaginationState & PaginationActions
> = set => ({
	page: INITIAL_PAGE,
	pageSize: INITIAL_PAGE_SIZE,
	totalCount: INITIAL_TOTAL_COUNT,
	canLoadMore: true,
	paginationRequest: INITIAL_PAGINATION_REQUEST,

	updatePagination: (
		pagination: PaginationResponse,
		availableCount: number,
	) => {
		set(() => ({
			page: pagination.page,
			pageSize: pagination.pageSize,
			totalCount: pagination.totalCount,
			canLoadMore: pagination.totalCount > availableCount,
			paginationRequest: {
				page: pagination.page + 1,
				pageSize: INITIAL_PAGE_SIZE,
			},
		}))
	},
	resetPagination: () =>
		set(() => ({
			page: INITIAL_PAGE,
			pageSize: INITIAL_PAGE_SIZE,
			totalCount: INITIAL_TOTAL_COUNT,
			canLoadMore: true,
			paginationRequest: INITIAL_PAGINATION_REQUEST,
		})),
})
